import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ORG_ADD } from "../../constant";
import OrganizationContext from "../../context/OrganizationContext/OrganizationContext";
import { LoaderResponse } from "../../Spinner";

const OrganizationAddPopup = () => {
  const {
    togglesubmit,
    modal,
    closeModel,
    createhwId,
    pageLimit,
    setPageLimit,
    orgName,
    setOrgName,
    check,
    setCheck,
    broker,
    setBroker,
    domain,
    setDomain,
    mainPageName,
    setMainPageName,
    subPageName,
    setSubPageName,
  } = useContext(OrganizationContext);

  const onCheck = () => {
    if (check === 0) {
      setCheck(1);
    } else {
      setCheck(0);
    }
  };

  return (
    <Modal size="lg" isOpen={modal} toggle={() => closeModel()}>
      {/* <LoaderResponse /> */}
      <ModalHeader toggle={() => closeModel()}>
        <span>Add Organization</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {togglesubmit === false ? (
                  <>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ORG_ADD.ORG_NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="orgName"
                          id="validationCustom14"
                          type="text"
                          placeholder={ORG_ADD.ORG_NAME}
                          value={orgName}
                          onChange={(e) => setOrgName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom16">
                          {ORG_ADD.PAGE_LIMIT}
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom16"
                          name="pageLimit"
                          type="number"
                          min={1}
                          placeholder={ORG_ADD.PAGE_LIMIT}
                          value={pageLimit}
                          onChange={(e) => setPageLimit(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Email."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ORG_ADD.LANDING_PAGE_NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="orgName"
                          id="validationCustom14"
                          type="text"
                          placeholder={ORG_ADD.LANDING_PAGE_NAME}
                          value={mainPageName}
                          onChange={(e) => setMainPageName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom16">
                          {ORG_ADD.BROKER_PAGE_NAME}
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom16"
                          name="pageLimit"
                          type="text"
                          placeholder={ORG_ADD.BROKER_PAGE_NAME}
                          value={subPageName}
                          onChange={(e) => setSubPageName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Email."}
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ORG_ADD.LANDING_PAGE_NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="orgName"
                          id="validationCustom14"
                          type="text"
                          placeholder={ORG_ADD.LANDING_PAGE_NAME}
                          value={mainPageName}
                          onChange={(e) => setMainPageName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom16">
                          {ORG_ADD.BROKER_PAGE_NAME}
                        </Label>
                        <Input
                          className="form-control"
                          id="validationCustom16"
                          name="pageLimit"
                          type="text"
                          placeholder={ORG_ADD.BROKER_PAGE_NAME}
                          value={subPageName}
                          onChange={(e) => setSubPageName(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Email."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ORG_ADD.PAGE_LIMIT}
                        </Label>
                        <Input
                          className="form-control"
                          name="orgName"
                          id="validationCustom14"
                          type="number"
                          min={1}
                          placeholder={ORG_ADD.PAGE_LIMIT}
                          value={pageLimit}
                          onChange={(e) => setPageLimit(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                <Col md="12 mb-4">
                  <Label htmlFor="validationCustom14">{ORG_ADD.DOMAIN}</Label>
                  <Input
                    className="form-control"
                    name="orgName"
                    id="validationCustom14"
                    type="text"
                    placeholder={ORG_ADD.DOMAIN}
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">
                    {"Please provide a valid Admin Name."}
                  </div>
                </Col>
                <div className="checkbox checkbox-primary">
                  <input
                    id="checkbox-primary-1"
                    type="checkbox"
                    onClick={onCheck}
                    checked={check === 1 ? "checked" : ""}
                  />
                  <Label htmlFor="checkbox-primary-1">
                    {"Allow Broker Modual ? "}
                  </Label>
                </div>
                {check === 1 && (
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom14">
                        {ORG_ADD.BROKER}
                      </Label>
                      <Input
                        className="form-control"
                        name="orgName"
                        id="validationCustom14"
                        type="number"
                        min={1}
                        placeholder={ORG_ADD.BROKER_LIMIT}
                        value={broker}
                        onChange={(e) => setBroker(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row style={{ paddingTop: "30px" }}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    value="Submit"
                    onClick={createhwId}
                  >
                    {ORG_ADD.SUBMIT}
                  </button>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default OrganizationAddPopup;
