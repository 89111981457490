import React, { useContext, useState } from "react";
import call from "../../../assets/images1/call-1.png";
import email from "../../../assets/images1/email-1.png";
import location from "../../../assets/images1/map-1.png";
import website from "../../../assets/images1/website-1.png";
import Downloade from "../../../assets/images1/brochure-1.png";
// import contact from "../../../assets/images1/contact-1.png";
import review from "../../../assets/images1/review-1.png";
import Sharing from "../../../assets/images1/share1.png";
import videobutton from "../../../assets/images1/video.png";
import qrcode from "../../../assets/images1/qrcode.png";
import Inquiry from "../../../assets/images1/inquiry.png";
import savecontact from "../../../assets/images1/savecontact.png";
import gallery from "../../../assets/images1/gallary.png";
import payment from "../../../assets/images1/payment.png";
import aboutMe from "../../../assets/images1/aboutme.png";
import DigitalCard from "../../../assets/images1/digitalcard.png";
// import card from "../../../assets/images1/card-1.png";
import Spinner from "../../../assets/images1/Iphone-spinner-2.gif";
// import Downloade from "../../../assets/images1/card-1.png";
import whatsapp from "../../../assets/images1/whatsapp-1.png";
import HomePageContext from "../../../context/HomepageContext/HomePageContext";
import { PAGE_ITEM_TYPE } from "../../../constant";
import { HyperLink } from "../../../utils/helper";
import { DownloadVfc } from "../../../api/Organization Api/OrganizationApi";
import { toast, Zoom } from "react-toastify";
import Theme2VideoGallary from "./theme2-VideoGallary";

const Theme2IconAndLogo = (props) => {
  const { abooutClick, clicktoCount } = props;
  const {
    logo,
    orgName,
    designation,
    formValue,
    setGalleryPopup,
    setContact,
    setShowQr,
    fname,
    lname,
    pageUuid,
    setSharePopup,
    setShareValue,
    showModal,
    setShowModal,
  } = useContext(HomePageContext);

  const [model, setModel] = useState(false);

  /**
   * Download to vcf file using onClick function
   */

  const onClick = async (qpiId, pageId) => {
    clicktoCount(qpiId, pageId);
    await DownloadVfc(pageUuid)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fname} ${lname}.vfc`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  /**
   * Video Popup open function
   */

  const VideoModelOpen = (qpiId, pageId) => {
    setModel(true);
    clicktoCount(qpiId, pageId);
  };

  /**
   * Gallary Popup open function
   */
  const GallaryModelOpen = (qpiId, pageId) => {
    // setGalleryPopup(true);
    setShowModal(true);
    clicktoCount(qpiId, pageId);
  };

  /**
   * Share Popup open Function
   */

  const openSharePopup = (value, qpiId, pageId) => {
    setShareValue(value);
    setSharePopup(true);
    clicktoCount(qpiId, pageId);
  };

  const InquiryPopup = (qpiId, pageId) => {
    setContact(true);
    clicktoCount(qpiId, pageId);
  };

  const QrPopupfun = (qpiId, pageId) => {
    setShowQr(true);

    clicktoCount(qpiId, pageId);
  };
  return (
    <div className="card-container">
      <div className="card-dtl text-center">
        <div className="businessmen-img">
          <img
            src={logo === "" ? Spinner : logo}
            className="img-fluid"
            alt="avtar"
          />
        </div>
        <h2 className="businessman-name">{orgName}</h2>
        <h6 className="business-name">{designation}</h6>
      </div>
      <div className="card-contact">
        <div className="contact-items">
          {formValue.map((elem, i) => {
            return elem.itemMaser.type === PAGE_ITEM_TYPE.DOWNLOAD ? (
              <a
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                href={elem.value}
                target="_blank"
                rel="noreferrer"
                onClick={() => clicktoCount(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={Downloade} className="img-fluid" alt="add-user" />
                </div>
                <p>{elem.label}</p>
              </a>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.SHARE ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() =>
                  openSharePopup(elem.value, elem.qpiId, elem.pageId)
                }
              >
                <div className="icon-img">
                  <img src={Sharing} className="img-fluid" alt="add-user" />
                </div>
                <p>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.VIDEO ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() => VideoModelOpen(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={videobutton} className="img-fluid" alt="add-user" />
                </div>
                <p>{"Video gallery"}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.QR_CODE ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() => QrPopupfun(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={qrcode} className="img-fluid" alt="add-user" />
                </div>
                <p>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.INQUIRY ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() => InquiryPopup(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={Inquiry} className="img-fluid" alt="add-user" />
                </div>
                <p>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.SAVE_CONTACT ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                key={i}
                onClick={() => onClick(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={savecontact} className="img-fluid" alt="add-user" />
                </div>
                <p>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY ? (
              <div
                className="card-item gallery"
                onClick={() => GallaryModelOpen(elem.qpiId, elem.pageId)}
                key={i}
              >
                {" "}
                <div className="icon-img">
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY
                        ? gallery
                        : ""
                    }
                    className="img-fluid"
                    alt="call"
                  />
                </div>
                <p>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.ABOUT_ME ? (
              <div
                className="card-item about"
                onClick={() =>
                  abooutClick(
                    elem.icon,
                    elem.label,
                    elem.value,
                    elem.itemMaser.type,
                    elem.qpiId,
                    elem.pageId
                  )
                }
                key={i}
              >
                {" "}
                <div className="icon-img">
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.ABOUT_ME
                        ? aboutMe
                        : ""
                    }
                    className="img-fluid"
                    alt="call"
                  />
                </div>
                <p>{elem.label}</p>
              </div>
            ) : (
              <a
                href={
                  elem.itemMaser.type === PAGE_ITEM_TYPE.CALL
                    ? `tel:${
                        elem.value.length <= 10
                          ? "+91" + elem.value
                          : elem.value
                      }`
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.WHATSAPP
                    ? `https://wa.me/${
                        elem.value.length <= 10
                          ? "+91" + elem.value
                          : elem.value
                      }`
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.EMAIL
                    ? `mailto:${elem.value}`
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.LOCATION
                    ? elem.value
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.WEBSITE
                    ? HyperLink(elem.value)
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.PAYMENT
                    ? elem.value
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.CUSTOM
                    ? HyperLink(elem.value)
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.DIGITALCARD
                    ? HyperLink(elem.value)
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.PAYMENT
                    ? elem.value
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.REVIEW
                    ? elem.value
                    : "/"
                }
                onClick={() => clicktoCount(elem.qpiId, elem.pageId)}
                target="_blank"
                rel="noreferrer"
                className="card-item"
                key={i}
              >
                <div className="icon-img">
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.CALL
                        ? call
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.EMAIL
                        ? email
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.LOCATION
                        ? location
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.WEBSITE
                        ? website
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.WHATSAPP
                        ? whatsapp
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.REVIEW
                        ? review
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.PAYMENT
                        ? payment
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.ABOUT_ME
                        ? aboutMe
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY
                        ? gallery
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.SAVE_CONTACT
                        ? savecontact
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.DIGITALCARD
                        ? DigitalCard
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.CUSTOM
                        ? elem.icon
                          ? elem.icon
                          : ""
                        : ""
                    }
                    style={{
                      borderRadius:
                        elem.itemMaser.type === PAGE_ITEM_TYPE.CUSTOM
                          ? "100%"
                          : "",
                    }}
                    className="img-fluid"
                    alt="call"
                  />
                </div>
                <p>{elem.label}</p>
              </a>
            );
          })}
          <Theme2VideoGallary
            setModel={setModel}
            model={model}
            formValue={formValue}
          />
        </div>
      </div>
    </div>
  );
};

export default Theme2IconAndLogo;
