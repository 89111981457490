import {
  Facebook,
  Instagram,
  MessageRounded,
  Share,
  Twitter,
} from "@material-ui/icons";
import { Email, Link, Telegram, WhatsApp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import copy from "copy-to-clipboard";
import {
  FacebookShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useParams } from "react-router";
import HomePageContext from "../../../context/HomepageContext/HomePageContext";
import useQuery from "../../../route/AuthRoutes";
import { SuccessToast } from "../../../auth/signinToast";
import { SHARE_POPUP } from "../../../constant";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Theme2SharePopup = (props) => {
  let query = useQuery();

  const { domain } = props;

  const { setSharePopup, sharePopup, orgName, pageId, shareValue } =
    useContext(HomePageContext);
  const [messageLink, setMessageLink] = useState("");
  let { hwId, brokerId } = useParams();

  /**
   * Link url use to copy url
   *
   */
  let linkUrl;
  if (brokerId) {
    linkUrl = `${domain && domain}${hwId}/${brokerId}${
      shareValue === "true"
        ? `?b=1${
            pageId === undefined && query.get("p") === null
              ? ""
              : `&p=${
                  pageId === undefined && query.get("p") === null
                    ? ""
                    : pageId || query.get("p")
                }`
          }`
        : pageId || query.get("p")
        ? `?p=${pageId || query.get("p")}`
        : ""
    }`;
  } else {
    linkUrl = `${domain && domain}${hwId}${
      shareValue === "true"
        ? `?b=1${
            pageId === undefined && query.get("p") === null
              ? ""
              : `&p=${
                  pageId === undefined && query.get("p") === null
                    ? ""
                    : pageId || query.get("p")
                }`
          }`
        : pageId || query.get("p")
        ? `?p=${pageId || query.get("p")}`
        : ""
    }`;
  }

  /**
   * copy url to use of copyToClipboard() function call
   */

  const copyToClipboard = () => {
    copy(linkUrl);
    SuccessToast.fire({
      icon: "success",
      title: "Copied",
    });
  };

  let windowUrl = window.location.href.split(hwId)[1];
  const shareUrl = domain + hwId + windowUrl;

  let message = `Hi! \nPlease click on the URL to visit Link of ${orgName} \n${shareUrl} \nThank You`;
  return (
    <Modal
      size="lg"
      isOpen={sharePopup}
      toggle={() => setSharePopup(!sharePopup)}
      centered
    >
      <ModalHeader toggle={() => setSharePopup(!sharePopup)}>
        <span>{SHARE_POPUP.SHARE_POP}</span>
      </ModalHeader>
      <ModalBody>
        <div className="btn-showcase" style={{ textAlign: "center" }}>
          <Tooltip title="Whats App">
            <WhatsappShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square whatsapp-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <WhatsApp />
                </div>
              </Button>
            </WhatsappShareButton>
          </Tooltip>
          <Tooltip title="Facebook">
            <FacebookShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square facebook-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <Facebook />
                </div>
              </Button>
            </FacebookShareButton>
          </Tooltip>
          <Tooltip title="Instagram">
            <InstapaperShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square instagram-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <Instagram />
                </div>
              </Button>
            </InstapaperShareButton>
          </Tooltip>
          <Tooltip title="Email">
            <EmailShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square Email-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <Email />
                </div>
              </Button>
            </EmailShareButton>
          </Tooltip>
          <Tooltip title="Telegram">
            <TelegramShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square Telegram-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <Telegram />
                </div>
              </Button>
            </TelegramShareButton>
          </Tooltip>
          <Tooltip title="Twitter">
            <TwitterShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square Twitter-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <Twitter />
                </div>
              </Button>
            </TwitterShareButton>
          </Tooltip>
          <Tooltip title="Facebook Massanger">
            <FacebookMessengerShareButton
              url={linkUrl}
              quote={"Shere Link"}
              hashtag="#React"
            >
              <Button className="btn-square Message-Bgcolor button-b-radius">
                <div className="iconcenter">
                  <MessageRounded />
                </div>
              </Button>
            </FacebookMessengerShareButton>
          </Tooltip>
          <Tooltip title="Copy">
            <Button
              className="btn-square instagram-Bgcolor button-b-radius"
              onClick={copyToClipboard}
            >
              <div className="iconcenter">
                <Link />
              </div>
            </Button>
          </Tooltip>
        </div>
        <hr />
        <ModalHeader>
          <Label htmlFor="validationCustom03">
            {"Enter To WhatsApp No. To Get Link"}
          </Label>
        </ModalHeader>
        <Row>
          <Col
            md="8 mb-3"
            calssName="form-control"
            style={{ marginTop: "28px" }}
          >
            <PhoneInput
              className="form-control"
              international
              defaultCountry="IN"
              placeholder="Enter Phone Number "
              value={messageLink}
              onChange={setMessageLink}
            />
          </Col>
          <Col
            md="4 mb-3"
            style={{
              marginTop: "28px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              href={`https://wa.me/${messageLink}?text=${encodeURIComponent(
                message
              )}`}
              data-action="share/whatsapp/share"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div>
                <Button
                  className="btn-square share-Bgcolor"
                  style={{
                    height: "52px",
                    borderRadius: "8px",
                    width: "100%",
                    fontSize: "18px",
                    letterSpacing: "1px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingRight: "9px",
                      }}
                    >
                      <Share />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingRight: "9px",
                      }}
                    >
                      {"Submit"}
                    </div>
                  </div>
                </Button>
              </div>
            </a>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Theme2SharePopup;
