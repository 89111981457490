import React from "react";
import Routers from "./route";
import { Provider } from "react-redux";
import store from "./store";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { auth0 } from "./data/config";
import AdminDeshboardState from "./context/AdminDeshboardContext/AdminDeshboardState";
import PrivillageMasterState from "./context/PrivillageMasterContext/PrivillageMasterState";
import ModualManagerState from "./context/ModuleManagerContext/ModualManagerState";
import ProfileComponentState from "./context/ProfilecomponentContext/PorileComponentState";
import PersonalPageState from "./context/PersonalPageContext/PersonalPAgeState";
import HomePageState from "./context/HomepageContext/HomePageState";
import PageItemState from "./context/PageItemContext/PageItemState";
import OrganizationState from "./context/OrganizationContext/OrganizationState";
import SignState from "./context/signinContext/SignState";
import BrokerComponentState from "./context/BrokercomponentContext/BrokerComponentState";
import BrokerPageState from "./context/BrokerPageContext/BrokerPAgeState";
import BrokerItemState from "./context/BrokertemContext/BrokertemState";
import LoaderState from "./context/LoaderContext/LoaderState";
import { LoaderResponse } from "./Spinner";
import PageItemMasterState from "./context/PageItemMasterContext/PageItemMasterState";

const App = () => (
  <div className="App">
    {/* <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      redirectUri={auth0.redirectUri}
    > */}
    <Provider store={store}>
      <LoaderState>
        <BrokerItemState>
          <BrokerComponentState>
            <BrokerPageState>
              <SignState>
                <PageItemMasterState>
                  <OrganizationState>
                    <PageItemState>
                      <HomePageState>
                        <PersonalPageState>
                          <ProfileComponentState>
                            <ModualManagerState>
                              <PrivillageMasterState>
                                <AdminDeshboardState>
                                  <LoaderResponse />
                                  <Routers />
                                </AdminDeshboardState>
                              </PrivillageMasterState>
                            </ModualManagerState>
                          </ProfileComponentState>
                        </PersonalPageState>
                      </HomePageState>
                    </PageItemState>
                  </OrganizationState>
                </PageItemMasterState>
              </SignState>
            </BrokerPageState>
          </BrokerComponentState>
        </BrokerItemState>
      </LoaderState>
    </Provider>
    {/* </Auth0Provider> */}
  </div>
);

export default App;
