export const SERVICES = {
  BYTEQR: "/byte-qr",
  BYTE_QR_NFC: "byte-qr-nfc",
  _PUBLIC: "byte-qr-nfc/public",
  GETWAY: "/gateway/admin",
  API: "/api",
};

export const V1 = "/api/v1";
export const ORGANIZATION = "organization";

/** PUBLIC API */
export const ORGANIZATION_DETAILS = `${SERVICES._PUBLIC}${V1}/organization/details`;
export const THEME_DETAILS = `${SERVICES._PUBLIC}${V1}/theme/detail`;
export const ITEM_VIEW = `${SERVICES._PUBLIC}${V1}/activity/itemView`;
export const SIGNIN = `${SERVICES._PUBLIC}${V1}/signup`;
export const LOGIN = `${SERVICES._PUBLIC}${V1}/login`;
/** PUBLIC API */

/** ORGANIZATION*/
export const ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const PAGE_PRIVIEW_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/activity/pageViewList`;
export const CREATE_ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const DELETE_ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const PAGE_CLONE = `${SERVICES.BYTE_QR_NFC}${V1}/page/clone`;
export const ORG_PAGELIST_DETAILS = `${SERVICES.BYTE_QR_NFC}${V1}/page/detail`;
export const EDIT_ORG_PAGE_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/page`;
export const ORG_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/page/enableDisable`;
export const PAGE_ITEM_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const DROP_PAGE_ITEM_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/qrItemMaster`;
export const CREATE_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const SHOW_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem/detail`;
export const DELETE_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const EDIT_ORG_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem`;
export const PAGE_ITEM_PAGE_LIST_ENABLE_DESABLE = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageItem/enableDisable`;
export const UPLOADE = `${SERVICES._PUBLIC}${V1}/file`;
export const ORG_PAGE_DETAILS = `${SERVICES._PUBLIC}${V1}/pageDetail`;
export const INQUIRY_PAGE = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageInquiry`;
export const SHOW_INQUIRY_PAGE = `${SERVICES.BYTE_QR_NFC}${V1}/qrPageInquiry/details`;
export const CREATE_INQUIRY_PAGE = `${SERVICES._PUBLIC}${V1}/organization/qrPageInquiry`;
export const DOWNLOAD_VFC = `${SERVICES._PUBLIC}${V1}/save-contact`;
export const SORT_ORDER_PAGE_ITEM = `${SERVICES.BYTE_QR_NFC}${V1}/shortOrderPageItem`;
export const DESHBOARD = `${SERVICES.BYTE_QR_NFC}${V1}/dashboard`;
export const UPDATE_ORGANIZATION = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;

export const PAGE_ITEM_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemType`;
export const ADD_PAGE_ITEM_MASTER_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/pageItemType`;

/** ORGANIZATION*/

/**SUPPER ADMIN  */

export const ORGANIZATION_LIST = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;
export const CREATE_HWID = `${SERVICES.BYTE_QR_NFC}${V1}/generate/hwId`;
export const UPDATE_PAGE_LIMIT = `${SERVICES.BYTE_QR_NFC}${V1}/organization`;

/**SUPPER ADMIN  */

/** LOGIN */
export const GETNFC = `${SERVICES.API}/nfc/get`;
export const CREATE_PROFILE = `${SERVICES.API}/completeBusinessProfile`;
/** LOGIN */

/** USER_LIST */

export const USER_LIST = `${SERVICES.BYTEQR}${V1}/pageDetail`;

/** USER_LIST */

/** DASHBOARD*/
export const SUPER_ADMIN_DESHBOARD = `${SERVICES.BYTEQR}${V1}/super_admin_dashboard/detail`;
export const SELLER_DESHBOARD = `${SERVICES.BYTEQR}${V1}/seller_dashboard/detail`;
export const NFC__LIST_STATUS_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order/status`;
export const NFC_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-detail`;
export const NFC_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/detail`;
export const NFC_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order`;
/** DASHBOARD*/

/** SELLER PASSWORD CHANGE */
export const PASSWORD_CHANGE = `${SERVICES.BYTE_QR_NFC}${V1}/user/change-password`;
/** SELLER PASSWORD CHANGE */

/** DOWNLOAD QR  */
export const DOWNLOAD_QR = `${SERVICES.BYTEQR}${V1}/qr_order/qrGeneratedDetails`;
export const DOWNLOAD_NFC = `${SERVICES.BYTEQR}${V1}/nfc_order/nfcGeneratedDetails`;
/** DOWNLOAD QR  */

/** PRIVILLAGE MASTER LIST*/
export const PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const ADD_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const EDIT_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DELETE_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DETAILS_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege/detail`;
export const MODULE_ACCESS_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/access`;
/** PRIVILLAGE MASTER LIST*/

/** MODUAL MASTER LIST*/
export const MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const ADD_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DETAILS_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/detail`;
export const EDIT_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DELETE_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
/** MODUAL MASTER LIST*/
