import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import down from "../../assets/images1/down-fill.svg";
import search from "../../assets/images1/search.svg";
import { BRANCH } from "../../constant";
import HomePageContext from "../../context/HomepageContext/HomePageContext";

const Branchis = (props) => {
  const { setOpen, open, setPageId } = useContext(HomePageContext);
  const {
    branch,
    branches,
    orgPageDetails,
    setBranches,
    searchApiData,
    color,
    title,
    themeDetail,
  } = props;
  const history = useNavigate();
  let { hwId } = useParams();

  const [searchFeild, setSearchFeild] = useState("");

  /**
   * Click to Branch to redirect perticler branch page open
   */

  const clickBranch = async (pageId) => {
    setPageId(pageId);
    let uri = `/${hwId}${window.location.href.split(hwId)[1].split("?")[0]}?`;
    if (branch) {
      uri += `b=${branch}&`;
    }
    if (pageId) {
      uri += `p=${pageId}`;
    }
    await themeDetail(pageId, true);
    await orgPageDetails(pageId);
    history(uri);
    setOpen(false);
  };

  /**
   * Searching to Branchis
   */

  const handleChange = (e) => {
    if (e.target.value === "") {
      setBranches(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item) =>
        item.pageTitle?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setBranches(filterResult);
    }
    setSearchFeild(e.target.value);
  };
  return (
    <div className="branch-wrapper">
      {branch === BRANCH.BRANCH ? (
        <p className="branch" onClick={() => setOpen(!open)}>
          {title}
          <img src={down} className="img-fluid" alt="down" />
        </p>
      ) : (
        ""
      )}
      {open && (
        <div
          className="branch-name active d-inline-flex flex-wrap"
          style={{
            background: color === null ? "#362457" : color.themeColor,
            border: "2px solid white",
          }}
        >
          <div className="branch-search position-relative w-100">
            <input
              type="text"
              placeholder="Search"
              value={searchFeild}
              onChange={(e) => handleChange(e)}
            />
            <button>
              <img src={search} className="img-fluid" alt="search" />
            </button>
          </div>
          {branches.map((elem) => {
            return (
              <p key={elem.pageId} onClick={() => clickBranch(elem.pageId)}>
                {elem.pageTitle}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Branchis;
