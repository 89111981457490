import React from "react";
import { useLocation } from "react-router";
import { https } from "../constant";

export const randomStr = (length) => {
  let randomText = "";
  const randomString =
    "ABCDEFGHIJKLMNPPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
  for (let i = 0; i < length; i++) {
    randomText += randomString.charAt(
      Math.floor(Math.random() * randomString.length)
    );
  }
  return randomText;
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**Hyper link function */

export function HyperLink(value) {
  value = value.split(https).pop();
  return https + value;
}

export const tableId = (page, pageSize, formvalue) => {
  for (let i in formvalue) {
    formvalue[i]["sNo"] = page * pageSize - pageSize + 1 + +i;
  }
  return formvalue;
};

/**
 * Max length for fild number type helper
 */

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

/**
 * {...} truncate function
 */

export const Truncate = (string, limit) => {
  let dots = "...";
  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
};

/**
 * Given Value is null or ! function
 */

export const isNullish = (value) => {
  debugger;
  if (typeof value == "undefined" || value == null) {
    return true;
  } else {
    return false;
  }
};

export const isEmpty = (value) => {
  let temp = {};
  Object.keys(value).forEach((key) => {
    debugger;
    if (!isNullish(value[key])) {
      temp[key] = value[key];
    }
  });
  return temp;
};
