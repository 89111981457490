import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GetNfc, ItemView, theme_Detail } from "../../api/Login Api/LoginApi";
import { OrgPageDetails } from "../../api/Organization Api/OrganizationApi";
import { WEBSITE_BASE } from "../../constant";
import HomePageContext from "../../context/HomepageContext/HomePageContext";
import SignContext from "../../context/signinContext/SignContext";
import useQuery from "../../route/AuthRoutes";
import HomePage from "./HomePage";

const BrokerPage = () => {
  const history = useNavigate();
  let query = useQuery();

  let { hwId, brokerId } = useParams();
  localStorage.setItem("hwId", hwId);

  const [branches, setBranches] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [domain, setDomain] = useState("");
  const [getPageId, setGetPageId] = useState("");
  const [templateId, setTemplateId] = useState("");

  const {
    setOrgName,
    setDesignation,
    setLogo,
    setFormValue,
    setSocialItem,
    setPageUuid,

    setFname,
    setLname,
    setWorkPhone,
    setTitle,
    setAddress,
    setEmailOrg,
    setOrgUuid,
    setQrcode,
    setPageId,
    orgId,
    setOrgId,
  } = useContext(HomePageContext);

  const { setSigninOrgName } = useContext(SignContext);

  // const brokerId = 2;

  const nfcGet = async () => {
    let formData = {
      hwId: hwId,
    };
    try {
      const { data } = await GetNfc(formData);
      if (data.status === 200) {
        setPageUuid(
          data.payload.orgPageDetail ? data.payload.orgPageDetail.pageUuid : ""
        );
        setOrgUuid(data.payload.data.orgUuid);
        setGetPageId(data.payload.data.pageId);
        data.payload.data.pageId && themeDetail(data.payload.data.pageId);
        if (data.payload.statusCode && data.payload.statusCode === "1") {
          localStorage.setItem("orgUuid", data.payload.data.orgUuid);
          localStorage.setItem("hwId", hwId);
          setSigninOrgName(data.payload.data.orgName);
          history(`/${hwId}/sign-in`);
        } else {
          if (query.get("p")) {
            history(`/${hwId}/${brokerId}?b=1&p=${query.get("p")}`);
          } else {
            history(`/${hwId}/${brokerId}?b=1`);
          }
        }
      } else {
        history(`/${hwId}/404`);
      }
    } catch (error) {
      history(`/${hwId}/404`);
    }
  };

  const themeDetail = async (pageId, condition) => {
    let formData;
    condition === true
      ? (formData = {
          pageId: pageId,
        })
      : (formData = {
          pageId: query.get("p") ? query.get("p") : pageId,
        });
    try {
      const { data } = await theme_Detail(formData);
      if (data.status === 200) {
        const payload = data.payload.data;
        setTemplateId(payload ? payload.templateId : "");
      } else {
        history(`/${hwId}/404`);
      }
    } catch (error) {
      history(`/${hwId}/404`);
    }
  };

  const orgPageDetails = async (pageId) => {
    setPageId(pageId);
    // const b = query.get("b");
    let formData = {
      hwId: hwId,
      pageId: pageId || query.get("p") || brokerId,
      b: "1",
      brokerId,
    };
    try {
      const { data } = await OrgPageDetails(formData);
      if (data.status === 200) {
        setBranches(data.payload.branches);
        setSearchApiData(data.payload.branches);
        setOrgName(data.payload.data.orgName);
        setFormValue(data.payload.data.pageItems);
        setDesignation(data.payload.data.designation);
        setLogo(data.payload.data.orgLogo);
        setIcon(data.payload.data.orgLogo);
        setSocialItem(data.payload.data.socialItems);
        setFname(data.payload.data.fname);
        setLname(data.payload.data.lname);
        setWorkPhone(data.payload.data.mobile);
        setTitle(data.payload.data.pageTitle);
        setAddress(data.payload.data.address);
        setEmailOrg(data.payload.data.email);
        setQrcode(data.payload.data.qrCode);
        setDomain(data.payload.data.orgDetail.domain);
        setOrgId(data.payload.data.orgId);
        setColor(
          data.payload.data ? JSON.parse(data.payload.data.pageThemeConfig) : ""
        );
        document.title =
          data.payload.data.pageTitle === null
            ? "Admin"
            : data.payload.data.pageTitle;
        document.getElementsByTagName("meta")[4].content =
          data.payload.data.orgLogo;
        document.getElementsByTagName("meta")[3].content =
          data.payload.data.pageTitle;
        document.getElementsByTagName("meta")[8].content =
          data.payload.data.pageTitle;
        document.getElementsByTagName("meta")[10].content = WEBSITE_BASE + hwId;
        document.getElementsByTagName("meta")[11].content =
          data.payload.data.pageTitle;
      } else {
        history(`/${hwId}/404`);
      }
    } catch (error) {}
  };

  const clicktoCount = async (qpiId, pageId) => {
    let formData = {
      pageId: pageId || query.get("p"),
      qpiId,
      orgId,
      brokerId: brokerId,
    };
    try {
      const { data } = await ItemView(formData);
      if (data.status === 200) {
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <HomePage
        nfcGet={nfcGet}
        branches={branches}
        searchApiData={searchApiData}
        color={color}
        icon={icon}
        orgPageDetails={orgPageDetails}
        setBranches={setBranches}
        clicktoCount={clicktoCount}
        brokerId={brokerId}
        domain={domain}
        themeDetail={themeDetail}
        getPageId={getPageId}
        templateId={templateId}
      />
    </>
  );
};

export default BrokerPage;
