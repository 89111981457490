import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { toast, Zoom } from "react-toastify";

import {
  CreateOrgPageList,
  Edit_OrgPageList,
  FILE_API,
  PageClone,
} from "../api/Organization Api/OrganizationApi";
import { classes } from "../data/layouts";
import Breadcrumbs from "../layout/breadcrumb";
import { BROKER, FILE_TYPE, TYPES } from "../constant";
import TopLoadingBar from "../utils/topLoadingBar";
import BrokerComponentItem from "./BrokerComponentItem";
import BrokerComponentContext from "../context/BrokercomponentContext/BrokercomponetContext";
import BrokerPageContext from "../context/BrokerPageContext/BrokerPageContaxt";
import BrokerItemContext from "../context/BrokertemContext/BrokertmContext";
import LoaderContext from "../context/LoaderContext/LoaderContext";
import { isEmpty } from "../utils/helper";
import SimpleReactValidator from "simple-react-validator";

const BrokerComponent = () => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    email,
    setEmail,
    fname,
    setFname,
    lname,
    setLname,
    pagetitle,
    setPageTitle,
    orgName,
    setOrgName,
    designation,
    setDesignation,
    address,
    setAddress,
    conuntry,
    setConuntry,
    state,
    setState,
    city,
    setCity,
    logo,
    setLogo,

    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    userEmail,
    setUserEmail,
    mobile,
    setMobile,
    seoTitle,
    setSeoTitle,
    seoDescription,
    setSeoDescription,
    seoKey,
    setSeoKey,

    editIndex,
    setIsAddHandle,
    check,
    setCheck,
    theme,
    color,
    data,
    setData,
    themeData,
    setThemeData,
    dialCode,
    setDialCode,
    // slugUrl,
    // setSlugUrl,
    // blockerSlugUrl,
    // setBlockerSlugUrl,
  } = useContext(BrokerComponentContext);

  const {
    togglesubmit,
    orgPageList,
    progress,
    setProgress,
    clone,
    setClone,
    setToggle,
  } = useContext(BrokerPageContext);

  const { pageUuid } = useContext(BrokerItemContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const history = useNavigate();

  let { hwId } = useParams();

  const [baseUrl, setBaseUrl] = useState("");

  /**
   *organization logo add
   */

  const readUrl = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setLogo(reader.result);
    };

    let formData = new FormData();
    formData.append("type", FILE_TYPE.ORGANIZATION_LOGO);
    formData.append("file", event.target.files[0]);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setLogo(data.payload.imageUrl);
        setBaseUrl(data.payload.baseUrl);
      } else {
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * onSubmit() function to use of organization add,clone and edit to use
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex > -1 && togglesubmit) {
        setLoaderShow(!loaderShow);
        let logoUrl = logo.split("byte-qr-nfc/").pop();
        let formData = isEmpty({
          seoTitle: seoTitle,
          seoKeyword: seoKey,
          seoDesc: seoDescription,
          mobile,
          userEmail,
          email,
          fname,
          lname,
          pageTitle: pagetitle,
          orgName,
          designation,
          address,
          country: conuntry,
          dialCode,
          state,
          city,
          // slugUrl,
          // blockerSlugUrl,
          templateId: themeData.value ? themeData.value : themeData[0].value,
          orgLogo: logoUrl,
          pageThemeConfig: {
            themeColor: theme ? theme : "#3e3088",
            fontColor: color ? color : "#FFFFFF",
          },
          type: TYPES.BROKER,
          AssignPages: data.map((elem) => {
            return {
              id: elem.value,
              label: elem.label,
            };
          }),
        });
        try {
          const { data } = await Edit_OrgPageList(formData, pageUuid);
          if (data.status === 200) {
            toast.success(data.message, {
              autoClose: 1000,
            });
            emptyFeild();
            history(`/${hwId}/BrokerPage/BrokerPage/${layout}`);
          } else {
          }
        } catch (error) {
          toast.error(error.response.data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else if (clone === false) {
        let logoUrl = logo.split("byte-qr-nfc/").pop();
        setLoaderShow(!loaderShow);
        let formData = isEmpty({
          pageUuid,
          seoTitle: seoTitle,
          seoKeyword: seoKey,
          seoDesc: seoDescription,
          mobile,
          userEmail,
          password,
          confirmPassword,
          email,
          fname,
          dialCode,
          lname,
          pageTitle: pagetitle,
          orgName,
          designation,
          address,
          country: conuntry,
          state,
          city,
          // slugUrl,
          // blockerSlugUrl,
          templateId: themeData.value ? themeData.value : themeData[0].value,
          orgLogo: logoUrl,
          pageThemeConfig: {
            themeColor: theme ? theme : "#3e3088",
            fontColor: color ? color : "#FFFFFF",
          },
          isUserCreate: check,
          type: TYPES.BROKER,
          AssignPages: data.map((elem) => {
            return {
              id: elem.value,
              label: elem.label,
            };
          }),
        });

        try {
          const { data } = await PageClone(formData);
          if (data.status === 200) {
            await orgPageList();

            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            emptyFeild();
            history(`/${hwId}/BrokerPage/BrokerPage/${layout}`);
          } else {
          }
        } catch (error) {
          toast.error(error.response.data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      } else {
        setLoaderShow(!loaderShow);
        let formData = isEmpty({
          seoTitle: seoTitle,
          seoKeyword: seoKey,
          seoDesc: seoDescription,
          mobile,
          userEmail,
          password,
          confirmPassword,
          email,
          fname,
          lname,
          pageTitle: pagetitle,
          orgName,
          designation,
          dialCode,
          address,
          country: conuntry,
          state,
          city,
          // slugUrl,
          // blockerSlugUrl,
          templateId: themeData.value,
          orgLogo: logo,
          pageThemeConfig: {
            themeColor: theme ? theme : "#3e3088",
            fontColor: color ? color : "#FFFFFF",
          },
          isUserCreate: check,
          type: TYPES.BROKER,
          AssignPages: data.map((elem) => {
            return {
              id: elem.value,
              label: elem.label,
            };
          }),
        });
        try {
          const { data } = await CreateOrgPageList(formData);
          if (data.status === 200) {
            await orgPageList();

            toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
            emptyFeild();
            history(`/${hwId}/BrokerPage/BrokerPage/${layout}`);
          } else {
          }
        } catch (error) {
          toast.error(error.response.data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * click to Pervius button to go to back be used to Privius() function
   */

  const emptyFeild = () => {
    setIsAddHandle(true);
    setClone(true);
    setToggle(false);
    setCheck(true);
    setThemeData([]);
    setData([]);
    setMobile("");
    setUserEmail("");
    setEmail("");
    setFname("");
    setLname("");
    setPageTitle("");
    setOrgName("");
    setDesignation("");
    setAddress("");
    setConuntry("");
    setState("");
    setCity("");
    setSeoDescription("");
    setSeoKey("");
    setSeoTitle("");
    setLogo("");
    setPassword("");
    setConfirmPassword("");
    // setBlockerSlugUrl("");
    // setSlugUrl("");
    setDialCode("+91");
    setData([]);
    setThemeData([]);
    setLoaderShow(false);
  };

  const Previus = () => {
    emptyFeild();
    history(`/${hwId}/BrokerPage/BrokerPage/${layout}`);
  };

  /**
   * useEffect to use of topLoading call
   */

  useEffect(() => {
    setProgress(100);
  }, []);

  const onCheck = () => {
    setCheck(!check);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={BROKER.PERENT} />
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <BrokerComponentItem
          onCheck={onCheck}
          readUrl={readUrl}
          baseUrl={baseUrl}
          onSubmit={onSubmit}
          Previus={Previus}
          simpleValidator={simpleValidator}
        />
      </Fragment>
    </>
  );
};

export default BrokerComponent;
