import React, { useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import LoaderContext from "../../../context/LoaderContext/LoaderContext";
import { Truncate } from "../../../utils/helper";

const SupperAdminDeshboard = (props) => {
  const { deshData, UpdateOrg } = props;

  const { deshboardAnimation } = useContext(LoaderContext);

  return (
    <>
      {/* <DeshboardAnimation /> */}
      {deshboardAnimation === true ? (
        ""
      ) : (
        <Row className="size-column">
          <Row>
            {deshData.map((elem, i) => {
              return (
                <Col xl="4" lg="12" md="6" className="box-col-6" key={i}>
                  <Card className="o-hidden">
                    <CardBody>
                      <div className="ecommerce-widgets media">
                        <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value="HTML"
                          style={{
                            height: "25px",
                            width: "5rem",
                            verticalAlign: "middle",
                          }}
                          checked={elem.orgDetail.pageId === elem.pageId}
                          onClick={() =>
                            UpdateOrg(elem.pageId, elem.orgDetail.orgUuid)
                          }
                        />
                        <div className="media-body">
                          <p className="f-w-500 font-roboto">
                            {elem.pageTitle}
                          </p>
                          <h6 className="f-w-500 mb-0 f-26">
                            <span className="counter">
                              {Truncate(elem.orgName, 12)}
                            </span>
                          </h6>
                        </div>
                        <div className="ecommerce-box light-bg-primary">
                          <img
                            src={elem.orgLogo}
                            style={{ width: "54px" }}
                            alt=""
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Row>
      )}
    </>
  );
};

export default SupperAdminDeshboard;
