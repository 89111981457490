import React, { useState, useEffect, useContext } from "react";
// import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, Zoom } from "react-toastify";
import man from "../assets/images/dashboard/profile.jpg";
import jwtImg from "../assets/images/jwt.svg";

import {
  Password,
  EmailAddress,
  AUTH0,
  JWT,
  // LoginWithJWT,
  ENDPOINT_IMAGE,
  TYPES,
  USER_ROLE,
} from "../constant";
import { useNavigate, useParams } from "react-router";
import { classes } from "../data/layouts";
import { Login_ } from "../api/Login Api/LoginApi";
import { Toast } from "./signinToast";
import { getHwId } from "../layout/sidebar/menu";
import { LogOut } from "../utils/Logout";
import { LoaderResponse } from "../Spinner";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  const [error, setError] = useState(false);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  let { hwId } = useParams();
  // localStorage.setItem("hwId", hwId);
  const login = async (e) => {
    e.preventDefault();
    if (email.length === 0 || password.length === 0) {
      setError(true);
    } else {
      setLoaderShow(!loaderShow);
      let fromData = {
        userEmail: email,
        hwId: hwId,
        password: password,
      };

      try {
        const { data } = await Login_(fromData);
        if (data.status === 200) {
          const payloade = data.payload.data;
          localStorage.setItem("token", `Bearer ${data.payload.token}`);
          localStorage.setItem("orgName", payloade.orgName);
          localStorage.setItem("hwId", hwId);
          localStorage.setItem("roleId", payloade.roleId);
          localStorage.setItem("types", payloade.type);
          localStorage.setItem("subPageName", payloade.orgDetail.subPageName);
          localStorage.setItem("mainPageName", payloade.orgDetail.mainPageName);
          localStorage.setItem("pageId", payloade.pageId);
          localStorage.setItem(
            "isBrokerAllowed",
            payloade.orgDetail.isBrokerAllowed
          );
          setValue(man);
          getHwId();
          if (
            TYPES.BROKER === +payloade.type &&
            USER_ROLE.PAGE_USER === +payloade.roleId
          ) {
            history(`/${getHwId()}/BrokerPage/BrokerPage/${layout}`);
          } else if (
            TYPES.LANDING_PAGE === +payloade.type &&
            USER_ROLE.PAGE_USER === +payloade.roleId
          ) {
            history(`/${getHwId()}/PersonalPage/PersonalPage/${layout}`);
          } else {
            history(`/${getHwId()}/dashboard/ecommerce/${layout}`);
          }
          setLoaderShow(false);

          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          return data.payload;
        } else {
          Toast.fire({
            icon: "error",
            title: data.message,
          });
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
        setLoaderShow(false);
      }
    }
  };
  if (
    hwId === localStorage.getItem("hwId") &&
    localStorage.getItem("token") &&
    localStorage.getItem("orgName")
  ) {
    history(`/${hwId}/dashboard/ecommerce/${layout}`);
  }

  if (hwId !== localStorage.getItem("hwId")) {
    LogOut();
  }

  useEffect(() => {
    localStorage.setItem("profileURL", value);
  }, [value]);
  return (
    <>
      <Container fluid={true} className="p-0">
        <div style={{ position: "relative" }}>
          <LoaderResponse />
          <Row>
            <Col xs="12">
              <div className="login-card">
                <div>
                  <div>
                    <a className="logo" href="index.html">
                      <img
                        className="img-fluid for-light"
                        src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                        style={{ height: "50px" }}
                        alt=""
                      />

                      <img
                        className="img-fluid for-dark"
                        src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                        style={{ height: "50px" }}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="login-main login-tab">
                    <Nav className="border-tab flex-column" tabs>
                      <NavItem>
                        <NavLink
                          className={selected === "jwt" ? "active" : ""}
                          onClick={() => setSelected("jwt")}
                        >
                          <img src={jwtImg} alt="" />
                          <span>{JWT}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={selected}
                      className="content-login"
                      style={{ height: "auto" }}
                    >
                      <TabPane className="fade show" tabId="jwt">
                        <Form className="theme-form" onSubmit={(e) => login(e)}>
                          <h4>{selected === "firebase" ? "Login" : "Login"}</h4>
                          <p>{"Login With valid Cradencials"}</p>
                          {error && email.length <= 0 ? (
                            <div className="mb-3">
                              <Label
                                className="col-form-label"
                                style={{ color: "red" }}
                              >
                                {EmailAddress}
                              </Label>
                              <Input
                                className="form-control"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={email}
                                placeholder="Enter a valid Email"
                                style={{ border: "2px solid red" }}
                              />
                            </div>
                          ) : (
                            <div className="mb-3">
                              <Label className="col-form-label">
                                {EmailAddress}
                              </Label>
                              <Input
                                className="form-control"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={email}
                                placeholder="Enter a valid Email"
                              />
                            </div>
                          )}

                          {error && password.length <= 0 ? (
                            <div className="mb-3 position-relative">
                              <Label
                                className="col-form-label"
                                style={{ color: "red" }}
                              >
                                {Password}
                              </Label>
                              <Input
                                className="form-control"
                                type={togglePassword ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                                defaultValue={password}
                                placeholder="Enter a valid Password"
                                style={{ border: "2px solid red" }}
                              />
                              <div
                                className="show-hide"
                                onClick={() =>
                                  setTogglePassword(!togglePassword)
                                }
                              >
                                <span
                                  className={togglePassword ? "" : "show"}
                                ></span>
                              </div>
                            </div>
                          ) : (
                            <div className="mb-3 position-relative">
                              <Label className="col-form-label">
                                {Password}
                              </Label>
                              <Input
                                className="form-control"
                                type={togglePassword ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                                defaultValue={password}
                                placeholder="Enter a valid Password"
                              />
                              <div
                                className="show-hide"
                                onClick={() =>
                                  setTogglePassword(!togglePassword)
                                }
                              >
                                <span
                                  className={togglePassword ? "" : "show"}
                                ></span>
                              </div>
                            </div>
                          )}

                          <div className="login-btn mb-0 mb-3 position-relative">
                            <div className="ms-3">
                              {(error && password.length <= 0) ||
                              (email && password.length <= 0) ? (
                                <Label style={{ color: "red" }} for="checkbox1">
                                  {"Please Enter valid Email and Password"}
                                </Label>
                              ) : (
                                <Label className="text-muted" for="checkbox1">
                                  {"Please Enter valid Email and Password"}
                                </Label>
                              )}
                            </div>
                            <Button
                              color="primary"
                              type="submit"
                              value="submit"
                            >
                              {"Login"}
                            </Button>
                          </div>
                        </Form>
                      </TabPane>
                      <TabPane className="fade show" tabId="auth0">
                        <div className="auth-content">
                          <img
                            src={require("../assets/images/auth-img.svg")}
                            alt=""
                          />
                          <h4>{"Welcome to login with Auth0"}</h4>
                          <p>
                            {
                              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
                            }
                          </p>
                          <Button color="info" onClick={loginWithRedirect}>
                            {AUTH0}
                          </Button>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

// export default withRouter(Logins);
export default Login;
