import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { PAGE_ITEM_TYPE } from "../../constant";
import HomePageContext from "../../context/HomepageContext/HomePageContext";

const AboutPage = (props) => {
  const { setAbout, about } = useContext(HomePageContext);
  const { abouticon, aboutlabel, aboutvalue, abouttype } = props;

  /**
   * CreateMarkup Function use to 1 line html to inner html content show
   * @param {Html Content} value
   * @returns return to convert inner html content
   */
  function createMarkup(value) {
    return { __html: value };
  }

  return (
    <Modal
      size="lm"
      isOpen={about}
      toggle={() => setAbout(!about)}
      className="inquiry-modal"
    >
      <ModalHeader
        className="justify-content-center border-0"
        toggle={() => setAbout(!about)}
      >
        <span>{aboutlabel}</span>
      </ModalHeader>
      <ModalBody>
        {abouttype === PAGE_ITEM_TYPE.ABOUT_ME ? (
          <div className="about-wrapper active">
            <div>
              {abouticon === "" ? null : (
                <div className="profile">
                  <img src={abouticon} className="img-fluid" alt="avtar" />
                </div>
              )}
              <div className="about-dtl">
                <div
                  dangerouslySetInnerHTML={createMarkup(aboutvalue)}
                  className="about-data"
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </ModalBody>
    </Modal>
  );
};

export default AboutPage;
