import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { QR_POPUP } from "../../../constant";
import HomePageContext from "../../../context/HomepageContext/HomePageContext";

const Theme2QrPopup = () => {
  const { showQr, setShowQr, qrcode } = useContext(HomePageContext);
  return (
    <Modal size="sm" isOpen={showQr} toggle={() => setShowQr(!showQr)}>
      <ModalHeader toggle={() => setShowQr(!showQr)}>
        <span>{QR_POPUP.QR_CODE}</span>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={qrcode} alt="" style={{ width: "18rem" }} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Theme2QrPopup;
