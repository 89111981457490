import React, { useContext, useEffect, useState } from "react";
import HomePageContext from "../../context/HomepageContext/HomePageContext";
import call from "../../assets/images1/call.png";
import emailIcon from "../../assets/images1/email.png";
import web from "../../assets/images1/web.png";
import whatsapp from "../../assets/images1/whatsapp.png";
import rupee from "../../assets/images1/rupee.png";
import placeholder from "../../assets/images1/placeholder.png";
import profile from "../../assets/images1/information-button.png";
import gallery from "../../assets/images1/gallery.png";
import qrcode from "../../assets/images1/qr-code.png";
import adduser from "../../assets/images1/add-user.png";
import review from "../../assets/images1/review.png";
import DigitalCard from "../../assets/images1/credit-card.png";
import Custome from "../../assets/images1/customize.png";
import Inquiry from "../../assets/images1/conversation-questions.png";
import Sharing from "../../assets/images1/sharing.png";
import Downloade from "../../assets/images1/arrow.png";
import Spinner from "../../assets/images1/Iphone-spinner-2.gif";
import PlayButton from "../../assets/images1/playBbutton.png";
import { toast, Zoom } from "react-toastify";
import { DownloadVfc } from "../../api/Organization Api/OrganizationApi";
import { PAGE_ITEM_TYPE } from "../../constant";
import VideoGallary from "./VideoGallary";
import { HyperLink } from "../../utils/helper";

const IconsAndLogo = (props) => {
  const { color, abooutClick, clicktoCount } = props;
  const {
    logo,
    orgName,
    designation,
    formValue,
    setGalleryPopup,
    setContact,
    setShowQr,
    fname,
    lname,
    pageUuid,
    setSharePopup,
    setShareValue,
  } = useContext(HomePageContext);

  const [model, setModel] = useState(false);

  /**
   * Download to vcf file using onClick function
   */

  const onClick = async (qpiId, pageId) => {
    clicktoCount(qpiId, pageId);
    await DownloadVfc(pageUuid)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fname} ${lname}.vfc`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  /**
   * thim style background color and font color set and useEffect are be used to css background and font change dainamicly
   */

  let appStyle;
  if (color === null) {
    appStyle = {
      background: { backgroundColor: "#362457" },
      color: { color: "white" },
    };
  } else {
    appStyle = {
      background: { backgroundColor: color.themeColor },
      color: { color: color.fontColor },
    };
  }

  useEffect(() => {
    if (color === null) {
      document.documentElement.style.setProperty("--theme-color", "black");
    } else {
      document.documentElement.style.setProperty(
        "--theme-color",
        color.themeColor
      );
    }
  }, []);

  /**
   * Video Popup open function
   */

  const VideoModelOpen = (qpiId, pageId) => {
    setModel(true);
    clicktoCount(qpiId, pageId);
  };

  /**
   * Gallary Popup open function
   */
  const GallaryModelOpen = (qpiId, pageId) => {
    setGalleryPopup(true);
    clicktoCount(qpiId, pageId);
  };

  /**
   * Share Popup open Function
   */

  const openSharePopup = (value, qpiId, pageId) => {
    setShareValue(value);
    setSharePopup(true);
    clicktoCount(qpiId, pageId);
  };

  const InquiryPopup = (qpiId, pageId) => {
    setContact(true);
    clicktoCount(qpiId, pageId);
  };

  const QrPopupfun = (qpiId, pageId) => {
    setShowQr(true);
    clicktoCount(qpiId, pageId);
  };

  return (
    <div className="card-container" style={appStyle.background}>
      <div className="card-dtl text-center">
        <div className="businessmen-img" style={{ background: "white" }}>
          <img
            src={logo === "" ? Spinner : logo}
            className="img-fluid"
            alt="avtar"
          />
        </div>
        <h2 className="businessman-name" style={appStyle.color}>
          {orgName}
        </h2>
        <h6 className="business-name" style={appStyle.color}>
          {designation}
        </h6>
      </div>
      <div className="card-contact">
        <div className="contact-items">
          {formValue.map((elem, i) => {
            return elem.itemMaser.type === PAGE_ITEM_TYPE.DOWNLOAD ? (
              <a
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                href={elem.value}
                target="_blank"
                rel="noreferrer"
                onClick={() => clicktoCount(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={Downloade} className="img-fluid" alt="add-user" />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </a>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.SHARE ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() =>
                  openSharePopup(elem.value, elem.qpiId, elem.pageId)
                }
              >
                <div className="icon-img">
                  <img src={Sharing} className="img-fluid" alt="add-user" />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.VIDEO ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() => VideoModelOpen(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={PlayButton} className="img-fluid" alt="add-user" />
                </div>
                <p style={appStyle.color}>{"Video gallery"}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.QR_CODE ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() => QrPopupfun(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={qrcode} className="img-fluid" alt="add-user" />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.INQUIRY ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                onClick={() => InquiryPopup(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={Inquiry} className="img-fluid" alt="add-user" />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.SAVE_CONTACT ? (
              <div
                className="card-item"
                data-bs-toggle="modal"
                data-bs-target="#inquiry-form"
                key={i}
                onClick={() => onClick(elem.qpiId, elem.pageId)}
              >
                <div className="icon-img">
                  <img src={adduser} className="img-fluid" alt="add-user" />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY ? (
              <div
                className="card-item gallery"
                onClick={() => GallaryModelOpen(elem.qpiId, elem.pageId)}
                key={i}
              >
                {" "}
                <div className="icon-img">
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY
                        ? gallery
                        : ""
                    }
                    className="img-fluid"
                    alt="call"
                  />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </div>
            ) : elem.itemMaser.type === PAGE_ITEM_TYPE.ABOUT_ME ? (
              <div
                className="card-item about"
                onClick={() =>
                  abooutClick(
                    elem.icon,
                    elem.label,
                    elem.value,
                    elem.itemMaser.type,
                    elem.qpiId,
                    elem.pageId
                  )
                }
                key={i}
              >
                {" "}
                <div className="icon-img">
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.ABOUT_ME
                        ? profile
                        : ""
                    }
                    className="img-fluid"
                    alt="call"
                  />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </div>
            ) : (
              <a
                href={
                  elem.itemMaser.type === PAGE_ITEM_TYPE.CALL
                    ? `tel:${
                        elem.value.length <= 10
                          ? "+91" + elem.value
                          : elem.value
                      }`
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.WHATSAPP
                    ? `https://wa.me/${
                        elem.value.length <= 10
                          ? "+91" + elem.value
                          : elem.value
                      }`
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.EMAIL
                    ? `mailto:${elem.value}`
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.LOCATION
                    ? elem.value
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.WEBSITE
                    ? HyperLink(elem.value)
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.PAYMENT
                    ? elem.value
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.CUSTOM
                    ? HyperLink(elem.value)
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.DIGITALCARD
                    ? HyperLink(elem.value)
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.PAYMENT
                    ? elem.value
                    : elem.itemMaser.type === PAGE_ITEM_TYPE.REVIEW
                    ? elem.value
                    : "/"
                }
                onClick={() => clicktoCount(elem.qpiId, elem.pageId)}
                target="_blank"
                rel="noreferrer"
                className="card-item"
                key={i}
              >
                <div className="icon-img">
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.CALL
                        ? call
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.EMAIL
                        ? emailIcon
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.LOCATION
                        ? placeholder
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.WEBSITE
                        ? web
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.WHATSAPP
                        ? whatsapp
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.REVIEW
                        ? review
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.PAYMENT
                        ? rupee
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.ABOUT_ME
                        ? profile
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY
                        ? gallery
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.SAVE_CONTACT
                        ? adduser
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.DIGITALCARD
                        ? DigitalCard
                        : elem.itemMaser.type === PAGE_ITEM_TYPE.CUSTOM
                        ? elem.icon
                          ? elem.icon
                          : Custome
                        : ""
                    }
                    style={{
                      borderRadius:
                        elem.itemMaser.type === PAGE_ITEM_TYPE.CUSTOM
                          ? "100%"
                          : "",
                    }}
                    className="img-fluid"
                    alt="call"
                  />
                </div>
                <p style={appStyle.color}>{elem.label}</p>
              </a>
            );
          })}

          <VideoGallary
            setModel={setModel}
            model={model}
            formValue={formValue}
          />
        </div>
      </div>
    </div>
  );
};

export default IconsAndLogo;
