import { useNavigate, useParams } from "react-router";
import { firebase_app } from "../data/config";

export const LogOut = () => {
  let { hwId } = useParams();
  const history = useNavigate();
  localStorage.removeItem("profileURL");
  localStorage.removeItem("token");
  localStorage.removeItem("orgName");
  localStorage.removeItem("roleId");
  localStorage.removeItem("orgId");
  localStorage.removeItem("pageUuid");
  localStorage.removeItem("orgUuid");
  localStorage.removeItem("hwId");
  firebase_app.auth().signOut();
  history(`${process.env.PUBLIC_URL}/${hwId}`);
};
