import React, { useState } from "react";
import { useContext } from "react";
import Slider from "react-slick";
import HomePageContext from "../../context/HomepageContext/HomePageContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { baseUrl, PAGE_ITEM_TYPE } from "../../constant";

const GalaryPopup = (props) => {
  const { formValue } = props;
  const { setGalleryPopup, galleryPopup } = useContext(HomePageContext);

  const [indexOfImages, setIndexOfImages] = useState(0);
  const [showModal, setShowModal] = useState(false);

  /**
   * Slider Setting
   */

  let settings = {
    dots: true,
  };

  /**
   * Image array to string to json parse code
   */

  let imgs;
  for (let t of formValue) {
    if (t.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY) {
      imgs = JSON.parse(t.value);
    } else {
    }
  }

  /**
   * Image Url Full Fill code
   */
  let imgsContent;
  if (imgs) {
    imgsContent = imgs.map((elem) => {
      return baseUrl + elem.url;
    });
  }

  /**
   * Image Open Function
   * @param {Image Index} index
   * @returns Index return to show this index image
   */
  const openModalAndSetIndex = (index) => {
    setIndexOfImages(index);
    setShowModal(true);
    setGalleryPopup(false);
    return;
  };
  return (
    <>
      <Modal
        size="lm"
        isOpen={galleryPopup}
        toggle={() => setGalleryPopup(false)}
        centered
        className="inquiry-modal"
      >
        <ModalHeader
          className="justify-content-center border-0"
          toggle={() => setGalleryPopup(false)}
        >
          <span>{PAGE_ITEM_TYPE.GALLARY_POPUP}</span>
        </ModalHeader>
        <ModalBody style={{ paddingBottom: "2rem" }}>
          <Slider
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={3000}
            {...settings}
          >
            {imgsContent !== undefined
              ? imgsContent.map((elem, index) => {
                  return (
                    <div className="modal-item" key={elem.id1}>
                      <img
                        src={elem}
                        className="img-fluid"
                        alt="blog-4"
                        onClick={() => openModalAndSetIndex(index)}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "900",
                            fontSize: "20px",
                            letterSpacing: "1px",
                          }}
                        >
                          {elem.name}
                        </p>
                      </div>
                    </div>
                  );
                })
              : null}
          </Slider>
        </ModalBody>
      </Modal>
      {showModal && (
        <Lightbox
          mainSrc={imgsContent[indexOfImages]}
          nextSrc={imgsContent[(indexOfImages + 1) % imgsContent.length]}
          prevSrc={
            imgsContent[
              (indexOfImages + imgsContent.length - 1) % imgsContent.length
            ]
          }
          onCloseRequest={() => setShowModal(false)}
          onMovePrevRequest={() =>
            setIndexOfImages(
              (indexOfImages + imgsContent.length - 1) % imgsContent.length
            )
          }
          onMoveNextRequest={() =>
            setIndexOfImages(
              (indexOfImages + imgsContent.length + 1) % imgsContent.length
            )
          }
        />
      )}
    </>
  );
};

export default GalaryPopup;
