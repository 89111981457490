import React, { useState, useEffect, useContext } from "react";
import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, Zoom } from "react-toastify";

import jwtImg from "../assets/images/jwt.svg";

import {
  Password,
  EmailAddress,
  AUTH0,
  JWT,
  ENDPOINT_IMAGE,
} from "../constant";
import { useNavigate, useParams } from "react-router";
import { SignIN } from "../api/Login Api/LoginApi";
import { Toast } from "./signinToast";
import { classes } from "../data/layouts";
import { getHwId } from "../layout/sidebar/menu";
import SignContext from "../context/signinContext/SignContext";

const Logins = () => {
  const { loginWithRedirect } = useAuth0();

  const {
    signinorgName,
    setSigninOrgName,
    fname,
    setFname,
    lname,
    setLname,
    email,
    setEmail,
    password,
    setPassword,
    conformpassword,
    setConformPassword,
    selected,
    setSelected,
    togglePassword,
    setTogglePassword,
    conformtogglePassword,
    setConformTogglePassword,
  } = useContext(SignContext);

  const history = useNavigate();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  const [error, setError] = useState(false);

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  let { hwId } = useParams();

  const login = async (e) => {
    e.preventDefault();
    const orgUuid = localStorage.getItem("orgUuid");
    if (
      email.length === 0 ||
      password.length === 0 ||
      conformpassword.length === 0
    ) {
      setError(true);
    } else {
      try {
        const { data } = await SignIN(
          email,
          password,
          conformpassword,
          orgUuid,
          signinorgName,
          fname,
          lname
        );
        if (data.status === 200) {
          localStorage.setItem("token", `Bearer ${data.payload.token}`);

          localStorage.setItem("hwId", hwId);
          localStorage.setItem(
            "orgName",
            data.payload.data.orgDetail
              ? data.payload.data.orgDetail.orgName
              : ""
          );
          setValue(man);
          getHwId();
          history(`/${hwId}/dashboard/ecommerce/${layout}`);

          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          return data.payload;
        } else {
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      }
    }
  };

  if (!localStorage.getItem("orgUuid")) {
    history(`/${hwId}`);
  }

  useEffect(() => {
    localStorage.setItem("profileURL", value);
  }, [value]);

  return (
    <>
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="index.html">
                    <img
                      className="img-fluid for-light"
                      src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                      style={{ height: "50px" }}
                      alt=""
                    />

                    <img
                      className="img-fluid for-dark"
                      src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                      style={{ height: "50px" }}
                      alt=""
                    />
                  </a>
                </div>
                <div className="login-main login-tab">
                  <Nav className="border-tab flex-column" tabs>
                    <NavItem>
                      <NavLink
                        className={selected === "jwt" ? "active" : ""}
                        onClick={() => setSelected("jwt")}
                      >
                        <img src={jwtImg} alt="" />
                        <span>{JWT}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={selected}
                    className="content-login"
                    style={{ height: "auto" }}
                  >
                    <TabPane className="fade show" tabId="jwt">
                      <Form className="theme-form" onSubmit={(e) => login(e)}>
                        <h4>
                          {/* {selected === "firebase" ? "Byte NFC" : "Byte NFC"} */}
                          {selected === "firebase" ? "Sign In" : "Sign In"}
                        </h4>
                        <p>{"Sign Up for New Account"}</p>

                        <div className="mb-3">
                          <Label className="col-form-label">{"Org Name"}</Label>
                          <Input
                            className="form-control"
                            type="text"
                            onChange={(e) => setSigninOrgName(e.target.value)}
                            defaultValue={signinorgName}
                            placeholder="Enter a Org Name"
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="col-form-label">
                            {"First Name"}
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            onChange={(e) => setFname(e.target.value)}
                            defaultValue={fname}
                            placeholder="Enter a First Name"
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="col-form-label">
                            {"Last Name"}
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            onChange={(e) => setLname(e.target.value)}
                            defaultValue={lname}
                            placeholder="Enter a Last Name"
                          />
                        </div>
                        {error && email.length <= 0 ? (
                          <div className="mb-3">
                            <Label
                              className="col-form-label"
                              style={{ color: "red" }}
                            >
                              {EmailAddress}
                            </Label>
                            <Input
                              className="form-control"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              defaultValue={email}
                              placeholder="Enter a Valid Email"
                              style={{ border: "2px solid red" }}
                            />
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Label className="col-form-label">
                              {EmailAddress}
                            </Label>
                            <Input
                              className="form-control"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              defaultValue={email}
                              placeholder="Enter a Valid Email"
                            />
                          </div>
                        )}

                        {error && password.length <= 0 ? (
                          <div className="mb-3 position-relative">
                            <Label
                              className="col-form-label"
                              style={{ color: "red" }}
                            >
                              {Password}
                            </Label>
                            <Input
                              className="form-control"
                              type={togglePassword ? "text" : "password"}
                              onChange={(e) => setPassword(e.target.value)}
                              defaultValue={password}
                              placeholder="Enter a Valid Password"
                              style={{ border: "2px solid red" }}
                            />
                            <div
                              className="show-hide"
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={togglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3 position-relative">
                            <Label className="col-form-label">{Password}</Label>
                            <Input
                              className="form-control"
                              type={togglePassword ? "text" : "password"}
                              onChange={(e) => setPassword(e.target.value)}
                              defaultValue={password}
                              placeholder="Enter a Valid Password"
                            />
                            <div
                              className="show-hide"
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={togglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </div>
                        )}

                        {error && conformpassword.length <= 0 ? (
                          <div className="mb-3 position-relative">
                            <Label
                              className="col-form-label"
                              style={{ color: "red" }}
                            >
                              {"Confirm Password"}
                            </Label>
                            <Input
                              className="form-control"
                              type={conformtogglePassword ? "text" : "password"}
                              onChange={(e) =>
                                setConformPassword(e.target.value)
                              }
                              defaultValue={conformpassword}
                              placeholder="Enter a Valid Password"
                              style={{ border: "2px solid red" }}
                            />
                            <div
                              className="show-hide"
                              onClick={() =>
                                setConformTogglePassword(!conformtogglePassword)
                              }
                            >
                              <span
                                className={conformtogglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3 position-relative">
                            <Label className="col-form-label">
                              {"Confirm Password"}
                            </Label>
                            <Input
                              className="form-control"
                              type={conformtogglePassword ? "text" : "password"}
                              onChange={(e) =>
                                setConformPassword(e.target.value)
                              }
                              defaultValue={conformpassword}
                              placeholder="Enter a Valid Password"
                            />
                            <div
                              className="show-hide"
                              onClick={() =>
                                setConformTogglePassword(!conformtogglePassword)
                              }
                            >
                              <span
                                className={conformtogglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </div>
                        )}

                        <div className="login-btn mb-0 mb-3 position-relative">
                          <Button color="primary" type="submit" value="submit">
                            {"Sign In"}
                          </Button>
                        </div>
                      </Form>
                    </TabPane>
                    <TabPane className="fade show" tabId="auth0">
                      <div className="auth-content">
                        <img
                          src={require("../assets/images/auth-img.svg")}
                          alt=""
                        />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>
                          {
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
                          }
                        </p>
                        <Button color="info" onClick={loginWithRedirect}>
                          {AUTH0}
                        </Button>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

// export default withRouter(Logins);
export default Logins;
