import React, { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import ProfileComponentContext from "../../context/ProfilecomponentContext/ProfilecomponetContext";
import { Edit_OrgPageList } from "../../api/Organization Api/OrganizationApi";
import { Zoom, toast } from "react-toastify";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import PageItemContext from "../../context/PageItemContext/PageItmContext";

const DefaultSelectPopup = (props) => {
  const { orgPageList } = props;
  const {
    callToActionPopup,
    setCalltoActionPopup,
    calltoActionData,
    landingName,
  } = useContext(PersonalPageContext);
  const { setActionQpiId, actionQpiId, type } = useContext(
    ProfileComponentContext
  );
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const { pageUuid } = useContext(PageItemContext);

  const handleCallToActionChange = async (itemId) => {
    setActionQpiId(itemId);
  };

  const handleSubmit = async () => {
    setLoaderShow(!loaderShow);
    let formData = {
      actionQpiId,
      type,
    };
    try {
      const { data } = await Edit_OrgPageList(formData, pageUuid);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
        });
        setLoaderShow(false);
        orgPageList();
        setCalltoActionPopup(false);
      } else {
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  return (
    <Modal
      size="lm"
      isOpen={callToActionPopup}
      toggle={() => setCalltoActionPopup(!callToActionPopup)}
    >
      <ModalHeader toggle={() => setCalltoActionPopup(!callToActionPopup)}>
        Call To Action
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody
            style={{
              padding: "58px",
              paddingTop: "10px",
              paddingBottom: "0px",
            }}
          >
            <ListGroup>
              <Label className="d-block" for={`edo-1`}>
                <Input
                  className="radio_animated"
                  id={`edo-1`}
                  type="radio"
                  name="rdo-ani"
                  onChange={() => handleCallToActionChange(0)}
                  defaultChecked={actionQpiId === 0}
                />
                {landingName}
              </Label>
            </ListGroup>
          </CardBody>
        </Card>
        <hr />
        <Card>
          {/* <CardHeader>
            <h5>{"Items"}</h5>
          </CardHeader> */}
          <h5>{"Items"}</h5>
          <CardBody>
            <ListGroup
              className="list-group-flush"
              style={{
                height: "31rem",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              {calltoActionData.map((elem, i) => {
                return (
                  elem.isActive === 1 && (
                    <ListGroupItem>
                      <Label className="d-block" for={`edo-${i}`}>
                        <Input
                          className="radio_animated"
                          id={`edo-${i}`}
                          type="radio"
                          name="rdo-ani"
                          onChange={() => handleCallToActionChange(elem.itemId)}
                          checked={actionQpiId === elem.itemId}
                        />
                        {elem.label}
                      </Label>
                    </ListGroupItem>
                  )
                );
              })}
            </ListGroup>
          </CardBody>
          <CardFooter
            style={{ padding: "20px", display: "flex", justifyContent: "end" }}
          >
            <Button onClick={() => handleSubmit()}>Submit</Button>
          </CardFooter>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default DefaultSelectPopup;
