import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  CreateOrgPageItem,
  DropDownPageItemList,
  Edit_OrgPageItem,
  FILE_API,
} from "../../api/Organization Api/OrganizationApi";
import PageItemContext from "../../context/PageItemContext/PageItmContext";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import CKEditors from "react-ckeditor-component";
import { FILE_TYPE, PAGE_ITEM_TYPE, TITLES } from "../../constant";
import { AddCircle, DeleteForever } from "@mui/icons-material";
import { randomStr } from "../../utils/helper";
import { IconButton } from "@mui/material";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { LoaderResponse } from "../../Spinner";

const PersonalPageAddmodal = (props) => {
  const { modal, setModal, feilds, setFilds } = props;

  const {
    change,
    setChange,
    label,
    setLabel,
    value,
    setValue,
    qpiId,
    type,
    setType,
    logo,
    setLogo,
    editIndex,
    shareValue,
    setShareValue,
  } = useContext(PersonalPageContext);
  const { nfcGet, pageUuid, title } = useContext(PageItemContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [formValue, setformValue] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  /**
   * Empty feilds function
   */

  const empltyFeild = () => {
    setLogo("");
    setBaseUrl("");
    setChange("");
    setLabel("");
    setValue("");
    setType("");
    setFilds([
      {
        id1: randomStr(5),
        name: "",
        url: "",
        discription: "",
      },
    ]);
    setLoaderShow(false);
  };

  /**
   * Get Dropdown List Dainamicly Function And useEffect
   */

  const getDropDownItem = async () => {
    try {
      const { data } = await DropDownPageItemList();

      if (data.status === 200) {
        setformValue(data.payload.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDropDownItem();
  }, []);

  /**
   * Uploade Files
   */

  const pageItemUploadFile = async ({ files, type }) => {
    let formData1 = new FormData();
    formData1.append("type", type);
    formData1.append("file", files[0]);

    try {
      const { data } = await FILE_API(formData1);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });

        return {
          imageUrl: data.payload.imageUrl,
          baseUrl: data.payload.baseUrl,
        };
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add page Item Function to call on Submit
   */

  const AddPageItem = async (e) => {
    setLoaderShow(!loaderShow);
    try {
      const formData = {
        itemId: change,
        pageUuid: pageUuid || localStorage.getItem("pageUuidForPageItem"),
        label,
        value:
          type === PAGE_ITEM_TYPE.SHARE
            ? shareValue
            : type === PAGE_ITEM_TYPE.SAVE_CONTACT
            ? PAGE_ITEM_TYPE.SAVE_CONTACT
            : type === PAGE_ITEM_TYPE.INQUIRY
            ? PAGE_ITEM_TYPE.INQUIRY
            : value,
        sortOrder: "1",
      };
      if (type === PAGE_ITEM_TYPE.VIDEO || type === PAGE_ITEM_TYPE.GALLAYRY) {
        formData["isObject"] = true;
        formData["value"] = feilds;
      }
      if (value.length) {
        formData[value] = value;
      }

      if (e.target.download_file && e.target.download_file.files.length > 0) {
        const { imageUrl, baseUrl } = await pageItemUploadFile({
          files: e.target.download_file.files,
          type: FILE_TYPE.DOWNLOADE,
        });
        formData["value"] = baseUrl + imageUrl;
      }
      if (e.target.about_icon && e.target.about_icon.files.length > 0) {
        const { imageUrl } = await pageItemUploadFile({
          files: e.target.about_icon.files,
          type: FILE_TYPE.PAGE_ITEM,
        });
        formData["icon"] = imageUrl;
      }
      if (e.target.custome_icon && e.target.custome_icon.files.length > 0) {
        const { imageUrl } = await pageItemUploadFile({
          files: e.target.custome_icon.files,
          type: FILE_TYPE.PAGE_ITEM,
        });
        formData["icon"] = imageUrl;
      }

      if (type === PAGE_ITEM_TYPE.GALLAYRY) {
        if (e.target.url && e.target.url.length > 0) {
          for (let i = 0; i < e.target.url.length; i++) {
            const { imageUrl } = await imageUploade({
              files: e.target.url[i].files,
              type: FILE_TYPE.GALLERY,
            });
            feilds[i].url = imageUrl;
          }
          setFilds(feilds);
        } else {
          const { imageUrl } = await imageUploade({
            files: e.target.url.files,
            type: FILE_TYPE.GALLERY,
          });
          feilds[0].url = imageUrl;
          // }
          setFilds(feilds);
        }
      }

      const { data } = await CreateOrgPageItem(formData);
      if (data.status === 200) {
        nfcGet();
        empltyFeild();
        setModal(false);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Edit Page Item Function
   */
  const EditPageItem = async (e) => {
    setLoaderShow(!loaderShow);
    let formData = {
      itemId: change,
      pageUuid: pageUuid || localStorage.getItem("pageUuidForPageItem"),
      label,
      value:
        type === PAGE_ITEM_TYPE.SHARE
          ? shareValue
          : type === PAGE_ITEM_TYPE.SAVE_CONTACT
          ? PAGE_ITEM_TYPE.SAVE_CONTACT
          : type === PAGE_ITEM_TYPE.INQUIRY
          ? PAGE_ITEM_TYPE.INQUIRY
          : value,
      sortOrder: "1",
    };
    if (type === PAGE_ITEM_TYPE.VIDEO || type === PAGE_ITEM_TYPE.GALLAYRY) {
      formData["isObject"] = true;
      formData["value"] = feilds;
    }
    if (e.target.download_file && e.target.download_file.files.length > 0) {
      const { imageUrl, baseUrl } = await pageItemUploadFile({
        files: e.target.download_file.files,
        type: 3,
      });
      formData["value"] = baseUrl + imageUrl;
    }
    if (e.target.about_icon && e.target.about_icon.files.length > 0) {
      const { imageUrl } = await pageItemUploadFile({
        files: e.target.about_icon.files,
        type: 2,
      });
      formData["icon"] = imageUrl;
    }
    if (e.target.custome_icon && e.target.custome_icon.files.length > 0) {
      const { imageUrl } = await pageItemUploadFile({
        files: e.target.custome_icon.files,
        type: 2,
      });
      formData["icon"] = imageUrl;
    }
    if (type === PAGE_ITEM_TYPE.GALLAYRY) {
      if (e.target.url && e.target.url.length > 0) {
        for (let i = 0; i < e.target.url.length; i++) {
          if (e.target.url[i].files.length) {
            const { imageUrl } = await imageUploade({
              files: e.target.url[i].files,
              type: FILE_TYPE.GALLERY,
            });
            feilds[i].url = imageUrl;
          }
        }
        setFilds(feilds);
      } else {
        if (e.target.url.files.length) {
          const { imageUrl } = await imageUploade({
            files: e.target.url.files,
            type: FILE_TYPE.GALLERY,
          });
          feilds[0].url = imageUrl;
          setFilds(feilds);
        }
      }
    }
    try {
      const { data } = await Edit_OrgPageItem(formData, qpiId);
      if (data.status === 200) {
        nfcGet();
        empltyFeild();
        setModal(false);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * submit to form as a additem time and edititem time
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (editIndex > -1 && !title) {
      EditPageItem(e);
    } else {
      AddPageItem(e);
    }
  };

  /**
   *Only set To show image function
   */
  const readUrl = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setLogo(reader.result);
    };
  };

  /**
   *dropdown handle change function to get value,label and type
   */
  const handleChange = (e) => {
    const [item] = formValue.filter((elem) => {
      return elem.itemId == e.target.value;
    });
    setChange(e.target.value);
    setLabel(item.label);
    setType(item.type);
  };

  /**
   *max lanth of number fild function
   */
  const maxLengthCheck = (object) => {
    debugger;
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  /**
   * onChange function are use to CK editor
   */

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setValue(newContent);
  };

  /**
   * Popup close to empty feild
   */

  const close = () => {
    empltyFeild();
    setModal(!modal);
  };

  /**
   * Image add function
   */

  const imageUploade = async ({ files, type }) => {
    let formData1 = new FormData();
    formData1.append("type", type);
    formData1.append("file", files[0]);

    try {
      const { data } = await FILE_API(formData1);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });

        return {
          imageUrl: data.payload.imageUrl,
          baseUrl: data.payload.baseUrl,
        };
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add more Function and remove function call
   */

  const handleAdd = (e) => {
    e.preventDefault();
    setFilds([
      ...feilds,
      {
        id1: randomStr(5),
        name: "",
        url: "",
        discription: "",
      },
    ]);
  };
  const hanldeRemove = (i, e) => {
    e.preventDefault();
    const values = [...feilds];
    values.splice(i, 1);
    setFilds(values);
  };

  const handleInputChange = (i, e) => {
    let param = [...feilds];
    param[i][e.target.name] = e.target.value;
    setFilds(param);
  };

  const handleShareValue = () => {
    if (shareValue === "true") {
      setShareValue("false");
    } else if (shareValue === "false") {
      setShareValue("true");
    }
  };

  return (
    <Modal size="lm" isOpen={modal} toggle={() => close()}>
      <ModalHeader toggle={() => close()}>
        {title ? (
          <span>{TITLES.ADD_PERSONAL_PAGE}</span>
        ) : (
          <span>{TITLES.EDIT_PERSONAL_PAGE}</span>
        )}
      </ModalHeader>
      <ModalBody>
        {/* <LoaderResponse /> */}
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  {type === PAGE_ITEM_TYPE.CUSTOM ? (
                    <Row>
                      <Col md="4 mb-4 mt-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={baseUrl + logo}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                name="custome_icon"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  border: "2px solid red",
                                  width: "10rem",
                                  position: "absolute",
                                  bottom: "-1rem",
                                  height: "12rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {type === PAGE_ITEM_TYPE.ABOUT_ME ? (
                    <Row>
                      <Col md="4 mb-4 mt-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={baseUrl + logo}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                name="about_icon"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  border: "2px solid red",
                                  width: "10rem",
                                  position: "absolute",
                                  bottom: "-1rem",
                                  height: "12rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom4">{"Types"}</Label>

                      <select
                        className="form-select"
                        style={{ marginBottom: "12px" }}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="select">--select--</option>
                        {formValue.map((elem) => {
                          return (
                            <option
                              key={elem.itemId}
                              value={elem.itemId}
                              name={elem.label}
                              selected={
                                elem.itemId === change ? "selected" : ""
                              }
                            >
                              {elem.label}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom4">{"Name"}</Label>
                      <Input
                        className="form-control"
                        name="name"
                        id="validationCustom4"
                        type="text"
                        placeholder="Name"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>

                  {type === PAGE_ITEM_TYPE.ABOUT_ME ? (
                    <Row>
                      <Col md="12 mb-4">
                        <CKEditors
                          activeclassName="p10"
                          content={value}
                          events={{
                            change: onChange,
                          }}
                        />
                      </Col>
                    </Row>
                  ) : type === PAGE_ITEM_TYPE.VIDEO ? (
                    feilds.map((elem, i) => (
                      <>
                        <div key={elem.id1}>
                          <Row>
                            <Col md="6 mb-4">
                              <Label htmlFor="validationCustom4">
                                {"Video Name"}
                              </Label>
                              <Input
                                className="form-control"
                                name="name"
                                id="validationCustom4"
                                type="text"
                                placeholder="Name"
                                value={elem.name}
                                maxLength={20}
                                onInput={maxLengthCheck}
                                onChange={(e) => handleInputChange(i, e)}
                                required
                              />
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col>
                            <Col md="6 mb-4">
                              <Label htmlFor="validationCustom4">
                                {"Video URL"}
                              </Label>
                              <Input
                                className="form-control"
                                name="url"
                                id="validationCustom4"
                                type="text"
                                placeholder="Video URL"
                                value={elem.url}
                                onChange={(e) => handleInputChange(i, e)}
                                required
                              />
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12 mb-4">
                              <Label htmlFor="validationCustom4">
                                {"Discription"}
                              </Label>
                              <Input
                                className="form-control"
                                name="discription"
                                id="validationCustom4"
                                type="textarea"
                                placeholder="Discription"
                                value={elem.discription}
                                onChange={(e) => handleInputChange(i, e)}
                                required
                              />
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              md="12 mb-4"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                top: "12px",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                style={{ background: "#dcf1dc" }}
                                onClick={(e) => handleAdd(e)}
                              >
                                <AddCircle style={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                style={{
                                  background: "#e1d7d7",
                                  marginLeft: "45px",
                                }}
                                disabled={i === 0}
                                onClick={(e) => hanldeRemove(i, e)}
                              >
                                <DeleteForever style={{ color: "red" }} />
                              </IconButton>
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ))
                  ) : type === PAGE_ITEM_TYPE.GALLAYRY ? (
                    feilds.map((elem, i) => (
                      <>
                        <div key={elem.id1}>
                          <Row>
                            <Col md="6 mb-4">
                              <Label htmlFor="validationCustom4">{"URL"}</Label>
                              <Input
                                className="form-control"
                                type="file"
                                name="url"
                                id="formFile"
                                // value={elem.url}
                                onChange={(e) => handleInputChange(i, e)}
                              />
                            </Col>
                            <Col md="6 mb-4">
                              <Label htmlFor="validationCustom4">
                                {"Image Title (Optional)"}
                              </Label>
                              <Input
                                className="form-control"
                                name="name"
                                id="validationCustom4"
                                type="text"
                                placeholder="Name"
                                value={elem.name}
                                onChange={(e) => handleInputChange(i, e)}
                                required
                              />
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {/* <Col md="6 mb-4">
                              <Label htmlFor="validationCustom4">
                                {"Discription"}
                              </Label>
                              <Input
                                className="form-control"
                                name="discription"
                                id="validationCustom4"
                                type="textarea"
                                placeholder="Discription"
                                value={elem.discription}
                                onChange={(e) => handleInputChange(i, e)}
                                required
                              />
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col> */}
                          </Row>
                          <Row>
                            <Col
                              md="12 mb-4"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                top: "12px",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                style={{ background: "#dcf1dc" }}
                                onClick={(e) => handleAdd(e)}
                              >
                                <AddCircle style={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                style={{
                                  background: "#e1d7d7",
                                  marginLeft: "45px",
                                }}
                                disabled={i === 0}
                                onClick={(e) => hanldeRemove(i, e)}
                              >
                                <DeleteForever style={{ color: "red" }} />
                              </IconButton>
                              <div className="invalid-feedback">
                                {"Please Provide a Valid Admin Name."}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ))
                  ) : (
                    <Row>
                      <Col md="12 mb-4">
                        {type === PAGE_ITEM_TYPE.SHARE ||
                        type === PAGE_ITEM_TYPE.SAVE_CONTACT ||
                        type === PAGE_ITEM_TYPE.INQUIRY ? null : (
                          <Label htmlFor="validationCustom4">{"Value"}</Label>
                        )}
                        {type === PAGE_ITEM_TYPE.WHATSAPP ? (
                          <Input
                            className="form-control"
                            name="value"
                            id="validationCustom4"
                            type="text"
                            placeholder="Value"
                            value={value}
                            min={"0"}
                            maxLength={15}
                            onInput={maxLengthCheck}
                            onChange={(e) => setValue(e.target.value)}
                            required
                          />
                        ) : type === PAGE_ITEM_TYPE.DOWNLOAD ? (
                          <Input
                            className="form-control"
                            name="download_file"
                            id="validationCustom4"
                            type="file"
                            // onChange={(e) => pdfJunrate(e)}
                          />
                        ) : type === PAGE_ITEM_TYPE.SAVE_CONTACT ||
                          type === PAGE_ITEM_TYPE.INQUIRY ? null : type ===
                          PAGE_ITEM_TYPE.SHARE ? (
                          <div className="checkbox checkbox-primary">
                            <input
                              id="checkbox-primary-1"
                              type="checkbox"
                              // onClick={onCheck}
                              checked={shareValue === "true"}
                              onChange={(e) => handleShareValue(e)}
                            />
                            <Label htmlFor="checkbox-primary-1">
                              {"With Dropdown"}
                            </Label>
                          </div>
                        ) : (
                          <Input
                            className="form-control"
                            name="value"
                            id="validationCustom4"
                            type="text"
                            placeholder="Value"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            required
                          />
                        )}
                        <div className="invalid-feedback">
                          {"Please Provide a Valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "18px",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {"Submit"}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PersonalPageAddmodal;
