import React, { useContext } from "react";
import facebook from "../../../assets/images1/facebook.png";
import youtube from "../../../assets/images1/youtube.png";
import twitter from "../../../assets/images1/twitter.png";
import linkedin from "../../../assets/images1/linkedin.png";
import instagram from "../../../assets/images1/instagram.png";
import android from "../../../assets/images1/android.png";
import apple from "../../../assets/images1/apple.png";
import telegram from "../../../assets/images1/telegram.png";
import whatsapp from "../../../assets/images1/whatsapp_Social.png";
import privius from "../../../assets/images1/exit.png";
import HomePageContext from "../../../context/HomepageContext/HomePageContext";
import { PAGE_ITEM_TYPE } from "../../../constant";
import { HyperLink } from "../../../utils/helper";
import { useNavigate, useParams } from "react-router";

const Theme2Follow = (props) => {
  const { orgPageDetails, themeDetail, quary } = props;
  const { socialItem } = useContext(HomePageContext);
  let { hwId, brokerId } = useParams();
  const history = useNavigate();

  const backToBroker = async () => {
    history(`/${hwId}/${brokerId}?b=1&p=${brokerId}`);
    await themeDetail(brokerId, true);
    orgPageDetails(brokerId);
  };
  return (
    <>
      <div className="card-follow active" style={{ background: "white" }}>
        {/* <p
          className="follow-me-btn"
          style={{ backgroundColor: "white", color: "black" }}
        >
          {PAGE_ITEM_TYPE.LINKS}
        </p> */}
        <div className="social-icons" style={{ background: "none" }}>
          <div
            className="social-icons d-flex flex-wrap align-items-center justify-content-center"
            style={{ backgroundColor: "white" }}
          >
            {brokerId !== quary?.get("p") && brokerId && (
              <div className="social-item">
                <img
                  src={privius}
                  className="social-item"
                  alt="youtube"
                  onClick={() => backToBroker()}
                />
              </div>
            )}
            {socialItem.map((elem, i) => {
              return (
                <a
                  href={
                    elem.itemMaser.type === PAGE_ITEM_TYPE.YOUTUBE
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.INSTAGRAM
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.LINKEDIN
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.FACEBOOK
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.TWITTER
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.ANDROID
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.APPLE
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.TELEGRAM
                      ? HyperLink(elem.value)
                      : elem.itemMaser.type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL
                      ? `https://wa.me/${
                          elem.value.length <= 10
                            ? "+91" + elem.value
                            : elem.value
                        }`
                      : "/"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="social-item"
                  key={i}
                  //   onClick={() => clicktoCount(elem.qpiId, elem.pageId)}
                >
                  <img
                    src={
                      elem.itemMaser.type === PAGE_ITEM_TYPE.YOUTUBE
                        ? youtube
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.INSTAGRAM
                        ? instagram
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.LINKEDIN
                        ? linkedin
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.FACEBOOK
                        ? facebook
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.TWITTER
                        ? twitter
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.ANDROID
                        ? android
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.APPLE
                        ? apple
                        : "" || elem.itemMaser.type === PAGE_ITEM_TYPE.TELEGRAM
                        ? telegram
                        : "" ||
                          elem.itemMaser.type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL
                        ? whatsapp
                        : ""
                    }
                    className="img-fluid"
                    alt="youtube"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Theme2Follow;
