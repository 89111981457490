export const Location = "Location";

export const New = "New";

export const TodayTotalSale = "Today Total sale";

export const Name = "Name";

export const ContactUs = "Contact Us";
export const Email = "Email";

export const Phone = "Phone";
export const EmailAddress = "Email Address";

export const Password = "Password";
export const LogOut = "Log Out";

export const Pagi_Nations = "Pagination";
export const PaginationWithIcons = "Pagination with icons";
export const PaginationAlignment = "Pagination alignment";
export const PaginationActiveDisabled = "Pagination with active and disabled";
export const PaginationColor = "Pagination Color";
export const PaginationSizing = "Pagination sizing";
export const Previous = "Previous";
export const Next = "Next";

// pages
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const CreateAccount = "Create Account";
export const AUTH0 = "AUTH0";
export const JWT = "JWT";
export const LoginWithJWT = "Login";

// layout
export const Loading = "Loading...";

export const Admin = "Admin";
export const Account = "Account";

export const IMAGE_URL =
  "https://mcode.fra1.digitaloceanspaces.com/byte-nfc/bytenfc.png";

export const DATA_NOT_FOUND =
  "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=900&t=st=1667466035~exp=1667466635~hmac=fb00d8e365338a2998c49c4661d0abb17de576ce3efd265331b8a98d6877bc5b";

export const ENDPOINT_IMAGE = "/byte-nfc/bytenfc.png";

// <<<<<<<<<<<<<<<--------------USER ROLE WISE ACCESS------------------->>>>>>>>>>>>>

export const ProfileKey = {
  ProductId: "c9e92390-331c-4b0e-8b74-8f2a3516ff30",
  category: "d93ddd94-df9d-43d2-8af1-ef3898318c74",
  whatsappCc: "+91",
  mobileCc: "+91",
};

export const PersonalPageDrop = [
  {
    name: "Whatsapp",
    value: "Whatsapp",
  },
  {
    name: "Call",
    value: "Call",
  },
  {
    name: "Link",
    value: "Link",
  },
  {
    name: "Location",
    value: "Location",
  },
  {
    name: "Email",
    value: "Email",
  },
  {
    name: "Download",
    value: "Download",
  },
  {
    name: "Save Contact",
    value: "Save Contact",
  },
  {
    name: "Reviews",
    value: "Reviews",
  },
  {
    name: "Website",
    value: "Website",
  },
  {
    name: "Text",
    value: "Text",
  },
];

export const hwStatusCode = {
  statusCode: 1,
  statusCodeTrue: "200",
};

export const USER_ROLE = {
  SUP_ADMIN: 1,
  PAGE_ADMIN: 2,
  PAGE_USER: 3,
};

export const NFC_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const QR_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const ORDER_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};

export const ORDER_TYPE = {
  BUY: 1,
  SELL: 2,
};

export const Role = [
  {
    id: 1,
    name: "super admin",
  },
  {
    id: 2,
    name: "Page admin",
  },
  {
    id: 3,
    name: "Page User",
  },
];

export const Style = {
  color: "white",
  width: "4rem",
  height: "4rem",
  borderRadius: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const WEBSITE_BASE = "https://byteqr.com/";

export const PAGE_ITEM_TYPE = {
  ABOUT_ME: "aboutme",
  DOWNLOAD: "download",
  CALL: "call",
  WHATSAPP: "WhatsApp",
  WHATSAPP_SOCIAL: "whatsapp",
  REVIEW: "Review",
  PAYMENT: "Payment",
  WEBSITE: "Website",
  LOCATION: "location",
  EMAIL: "email",
  GALLAYRY: "gallary",
  SAVE_CONTACT: "saveContact",
  DIGITALCARD: "DigitalCard",
  CUSTOM: "custom",
  INQUIRY: "inquiry",
  VIDEO: "video",
  QR_CODE: "qrCode",
  SHARE: "share",
  YOUTUBE: "youtube",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  FACEBOOK: "Facebook",
  TWITTER: "twiter",
  LINKS: "Links",
  ANDROID: "android",
  APPLE: "ios",
  TELEGRAM: "telegram",
  GALLARY_POPUP: "Gallary",
};

export const SORTING_ORDER = {
  ASC: "ASC",
  DSC: "DSC",
};

export const FILE_TYPE = {
  ORGANIZATION_LOGO: 1,
  PAGE_ITEM: 2,
  DOWNLOADE: 3,
  GALLERY: 4,
};

export const OPTION = [
  {
    value: 1,
    name: "READ",
  },
  {
    value: 2,
    name: "WRITE",
  },
  {
    value: 3,
    name: "UPDATE",
  },
  {
    value: 4,
    name: "DELETE",
  },
  {
    value: 5,
    name: "DOWNLOAD",
  },
];

export const TITLES = {
  ADD_PERSONAL_PAGE: "Add Personal Page",
  EDIT_PERSONAL_PAGE: "Edit Personal Page",
  PAGE_ITEMS: "Page Items",
  CLONE_PROFILE: "Clone Profile",
  COMPLITE_YOUR_PROFILE: "Complete Your Profile",
  Edit_YOUR_PROFILE: "Edit Your Profile",
  LANDING_PAGE: "Landing Page",
};

export const ORG_ADD = {
  ORG_NAME: "Org Name",
  LANDING_PAGE_NAME: "Landing Page Name",
  BROKER_PAGE_NAME: "Broker Page Name",
  PAGE_LIMIT: "Page Limit",
  DOMAIN: "Domain",
  SUBMIT: "Submit",
  ORGANIZATION: "Organization",
  BROKER: "Broker",
  BROKER_LIMIT: "Broker Limit ...",
};

export const PAGE_ITEM_MASTER_ADD = {
  TYPE: "Type",
  LABEL: "Label",
  HAYPER_LINK: "Hyper link",
  CATEGORY: "Category",
  ACTION: "Action",
  IS_CALL_TO_ACTION: "Is Call-to-action",
  PAGE_ITEM: "Page Item",
};

export const INQUARY_LIST = {
  INQUIRY_PAGE: "Inquiry Page",
  INQUIRY: "Inquiry",
  SUBMIT: "Submit",
  NAME: "Name*",
  PHONE: "Phone*",
  EMAIL: "Email*",
  SUBJECT: "Subject",
  DESCRIPTION: "Description",
};

export const PAGE_ITEM_MASTER = {
  PAGE_ITEM_MASTER_PERENT: "Page Item Master",
  ADD_PAGE_ITEM_MASTER: "Add Page Item Master",
};

export const QR_POPUP = {
  QR_CODE: "Qr Code",
};

export const SHARE_POPUP = {
  SHARE_POP: "Share on Social Media",
};

export const baseUrl = "https://mcode.fra1.digitaloceanspaces.com/byte-qr-nfc/";

export const https = "https://";

/**
 * type of landing or broker
 */

export const TYPES = {
  LANDING_PAGE: 1,
  BROKER: 2,
};

/**
 * broker constant
 */

export const BROKER = {
  PERENT: "Broker Page",
  BROKER_PROFILE: "Broker Profile",
  EMAIL: "Email",
  BROKER_TITLE: "Broker Title",
  CONTACT_US: "ContactUs",
  LOCATION: "Location",
  COUNTRY: "Country",
  STATE: "State",
  CITY: "City",
  ADD_BROEKR_PAGE: "Add Broker Page",
  EDIT_BROEKR_PAGE: "Edit Broker Page",
  BROKER_PAGEITEM: "Broker Page Item",
  CLONE_BROKER: "Clone Broker",
  COMPLETE_BROKER_PROFILE: "Complite Broker Profile",
  EDIT_BROKER_PROFILE: "Edit Broker Profile",
};

export const BRANCH = {
  BRANCH: "1",
};

/**
 * page limit and broker limit constant
 */

export const LIMIT = {
  TOTAL_PAGE_LIMIT: "Total Page Limit",
  AVAILABLE_PAGE_LIMIT: "Available Page Limit",
  TOTAL_BROKER_LIMIT: "Total Broker Limit",
  AVAILABLE_BROKER_LIMIT: "Available Broker Limit",
};

/**
 * disabled delete button
 */

export const DELETE = {
  color_disabled: "#c99b9b",
  color: "red",
};

/**
 * moment formate constant
 */

export const MOMENT_SETTING = {
  FORMATE: "YYYY-MM-DD",

  FORMATE_WITH_TIME: "DD MMM, YYYY HH:MM",
  TIME_ZONE: "+05:30:00",
};

/**
 * Theme select dropdown data
 */

export const THEME_SELECT = [
  {
    label: "Theme 1 (Colour Selection)",
    value: 1,
  },
  {
    label: "Theme 2",
    value: 2,
  },
];

export const TMPLATE = {
  new: 2,
  old: 1,
};
