import * as React from "react";
import { useContext } from "react";
import { useState } from "react";
import { OrgPageList } from "../../api/Organization Api/OrganizationApi";
import { TYPES } from "../../constant";
import LoaderContext from "../LoaderContext/LoaderContext";
import PersonalPageContext from "./PersonalPageContaxt";

const PersonalPageState = (props) => {
  const { tabelAnimation, setTableAnimation } = useContext(LoaderContext);

  const [formvalue, setformValue] = useState([]);
  const [pageLimit, setPageLimit] = useState("");
  const [togglesubmit, setToggle] = useState(false);
  const [title, setTitle] = useState(true);
  const [clone, setClone] = useState(true);
  const [priviewPopup, setPreviewPopup] = useState(false);
  const [callToActionPopup, setCalltoActionPopup] = useState(false);
  const [calltoActionData, setCalltoActionData] = useState([]);
  const [landingName, setLandingName] = useState("");
  const [priviewArr, setPreviewArr] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [dates, setDates] = useState("");
  const [endDates, setEndDates] = useState("");
  const [chartTitle, setChartTitle] = useState("");

  const [change, setChange] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [shareValue, setShareValue] = useState("false");
  const [type, setType] = useState("");
  const [logo, setLogo] = useState("");

  const [orgId, setOrgId] = useState("");

  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [record, setRecord] = useState(0);
  const [progress, setProgress] = useState(0);
  const [pageUuid, setPageUuid] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [editIndex, seteditIndex] = useState(-1);

  const [qpiId, setQpiId] = useState(0);

  /**showDetails State */

  const [allDetails, setAllDetails] = useState("");

  const orgPageList = async (desabledStatusLoader) => {
    if (
      sortOrder === "ASC" ||
      sortOrder === "DSC" ||
      desabledStatusLoader === false
    ) {
    } else {
      setTableAnimation(!tabelAnimation);
    }
    try {
      let paramObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: { type: TYPES.LANDING_PAGE },
      };
      const { data } = await OrgPageList(paramObj);

      if (data.status === 200) {
        setformValue(data.payload.data);
        setPageLimit(data.payload.pageLimit);
        setRecord(data.pager.totalRecords);

        setTableAnimation(false);
      } else {
      }
    } catch (error) {
      setTableAnimation(false);
    }
  };

  return (
    <>
      <PersonalPageContext.Provider
        value={{
          orgPageList,
          formvalue,
          setformValue,
          togglesubmit,
          setToggle,
          sortOrder,
          setSortOrder,
          pageSize,
          setPageSize,
          page,
          setPage,
          sortBy,
          setSortBy,
          record,
          setRecord,
          progress,
          setProgress,
          pageUuid,
          setPageUuid,
          clone,
          setClone,
          isSwitchOn,
          setIsSwitchOn,
          title,
          setTitle,
          qpiId,
          setQpiId,
          editIndex,
          seteditIndex,
          change,
          setChange,
          label,
          setLabel,
          value,
          setValue,
          orgId,
          setOrgId,
          allDetails,
          setAllDetails,
          type,
          setType,
          logo,
          setLogo,
          pageLimit,
          setPageLimit,
          shareValue,
          setShareValue,
          priviewPopup,
          setPreviewPopup,
          priviewArr,
          setPreviewArr,
          dates,
          setDates,
          endDates,
          setEndDates,
          categories,
          setCategories,
          chartData,
          setChartData,
          chartTitle,
          setChartTitle,
          callToActionPopup,
          setCalltoActionPopup,
          calltoActionData,
          setCalltoActionData,
          landingName,
          setLandingName,
        }}
      >
        {props.children}
      </PersonalPageContext.Provider>
    </>
  );
};

export default PersonalPageState;
