import axios from "axios";
import {
  CREATE_PROFILE,
  ITEM_VIEW,
  LOGIN,
  ORGANIZATION_DETAILS,
  SIGNIN,
  THEME_DETAILS,
} from "../../constant/API constant";

export const SignIN = (
  email,
  password,
  conformpassword,
  orgUuid,
  orgName,
  fname,
  lname
) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${SIGNIN}`, {
    userEmail: email,
    orgName: orgName,
    fname: fname,
    lname: lname,
    password: password,
    confirmPassword: conformpassword,
    orgUuid: orgUuid,
  });
};

export const Login_ = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${LOGIN}`, data);
};

export const GetNfc = (formData) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${ORGANIZATION_DETAILS}`, {
    params: formData,
  });
};

export const theme_Detail = (formData) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${THEME_DETAILS}`, {
    params: formData,
  });
};

export const ItemView = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${ITEM_VIEW}`, formData, {
    // params: formData,
  });
};

export const CreateProfile = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_PROFILE}`,
    formData,
    {
      headers: { token: jwt_token },
    }
  );
};
