import React from "react";
import { useContext } from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { INQUARY_LIST } from "../../constant";
import HomePageContext from "../../context/HomepageContext/HomePageContext";

const InquaryPage = (props) => {
  const {
    name,
    email,
    mobile,
    subject,
    message,
    setName,
    setEmail,
    setMobile,
    setMessage,
    setSubject,
    setContact,
    contact,
  } = useContext(HomePageContext);
  const { onSubmit } = props;
  return (
    <>
      <Modal
        size="lm"
        isOpen={contact}
        toggle={() => setContact(!contact)}
        centered
        className="inquiry-modal"
      >
        <ModalHeader
          className="justify-content-center border-0"
          toggle={() => setContact(!contact)}
        >
          <span>{INQUARY_LIST.INQUIRY}</span>
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" onSubmit={(e) => onSubmit(e)}>
            <div className="form-item">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder={INQUARY_LIST.NAME}
                id="name"
              />
            </div>
            <div className="form-item">
              <input
                type="tel"
                className="form-control"
                placeholder={INQUARY_LIST.PHONE}
                id="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="form-item">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder={INQUARY_LIST.EMAIL}
                id="email"
              />
            </div>
            <div className="form-item">
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="form-control"
                placeholder={INQUARY_LIST.SUBJECT}
                id="subject"
              />
            </div>
            <div className="form-item">
              <textarea
                className="form-control"
                placeholder={INQUARY_LIST.DESCRIPTION}
                id="message"
                rows="3"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="theme-btn d-table mx-auto">
              {INQUARY_LIST.SUBMIT}
            </button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InquaryPage;
