// dashbaord
import Ecommerce from "../components/dashboard/ecommerce";
import PrivillageManager from "../components/PrivillageManager/PrivillageManager";
import ModualManager from "../components/ModualManager/ModualManager";
import PersonalPage from "../components/PersonalPage/PersonalPage";
import ProfileComponent from "../components/ProfileComponent";
import InquiryPage from "../components/InquiryPage/InquiryPage";
import PersonalPageShow from "../components/PersonalPage/PersonalPageShow";
import AddPageItem from "../components/PageItem/AddPageItem";
import Organization from "../components/Organization/Organization";
import BrokerPage from "../components/BrokerPage/BrokerPage";
import BrokerComponent from "../components/BrokerComponent";
import BrokerAddmodal from "../components/BrokerPageItem/AddBrokerPageItem";
import BrokerPageShow from "../components/BrokerPage/BrokerPageShow";
import PageItemMaster from "../components/PageItemMaster/PageItemMaster";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/:hwId/dashboard/ecommerce/:layout/`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PersonalPage/PersonalPage/:layout/`,
    Component: <PersonalPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerPage/BrokerPageShow/:layout/`,
    Component: <BrokerPageShow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/ProfileComponent/:layout/`,
    Component: <ProfileComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PageItem/AddPageItem/:layout/`,
    Component: <AddPageItem />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PersonalPage/PersonalPageShow/:layout/`,
    Component: <PersonalPageShow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/InquiryPage/InquiryPage/:layout/`,
    Component: <InquiryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/Organization/Organization/:layout/`,
    Component: <Organization />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PageItemMaster/PageItemMaster/:layout/`,
    Component: <PageItemMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerPage/BrokerPage/:layout/`,
    Component: <BrokerPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerPageItem/AddBrokerPageItem/:layout/`,
    Component: <BrokerAddmodal />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/BrokerComponent/:layout/`,
    Component: <BrokerComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/PrivillageManager/PrivillageManager/:layout/`,
    Component: <PrivillageManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/:hwId/ModualManager/ModualManager/:layout/`,
    Component: <ModualManager />,
  },
];
