import React, { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "reactstrap";

import SupperAdminDeshboard from "./supperAdminDeshboard/supperAdminDeshboard";
import LoadingBar from "react-top-loading-bar";
import { useNavigate, useParams } from "react-router";
import { GetNfc } from "../../api/Login Api/LoginApi";
import {
  DeshBoard,
  UpdateOrganization,
} from "../../api/Organization Api/OrganizationApi";
import { toast, Zoom } from "react-toastify";
import { LogOut } from "../../utils/Logout";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const Ecommerce = () => {
  const [progress, setProgress] = useState(0);
  const [deshData, setDashData] = useState([]);

  const { setDeshboardAnimation, deshboardAnimation } =
    useContext(LoaderContext);

  const history = useNavigate();
  let { hwId } = useParams();

  /**
   * Public Api Call Function
   */

  const nfcGet = async () => {
    let formData = {
      hwId: hwId,
    };
    try {
      if (!hwId) {
        history(`/${hwId}/404`);
      }
      const { data } = await GetNfc(formData);
      if (data.status === 200) {
        localStorage.setItem("pageUuid", data.payload.orgPageDetail.pageUuid);
      } else {
        history(`/${hwId}/404`);
      }
    } catch (error) {}
  };

  useEffect(() => {
    nfcGet();
  }, []);

  /**
   * Deshboard Api Function
   */

  const Deshboard = async () => {
    setDeshboardAnimation(!deshboardAnimation);
    try {
      const { data } = await DeshBoard();

      if (data.status === 200) {
        setDashData(data.payload.data);
        setDeshboardAnimation(false);
      } else {
      }
    } catch (error) {
      setDeshboardAnimation(false);
    }
  };

  useEffect(() => {
    Deshboard();
  }, []);

  /**
   * Update Org Default Page
   */

  const UpdateOrg = async (pageId, orgUuid) => {
    let formData = {
      pageId: pageId,
    };
    try {
      const { data } = await UpdateOrganization(formData, orgUuid);

      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        Deshboard();
      } else {
        toast.error(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {}
  };

  /**
   * if hwId and localsorage store (hwId) are not sem to logout function call
   */

  if (hwId !== localStorage.getItem("hwId")) {
    LogOut();
  }

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Default Landing Page" />
      <Container fluid={true}>
        <LoadingBar progress={progress} setProgress={setProgress} />
        <SupperAdminDeshboard deshData={deshData} UpdateOrg={UpdateOrg} />
      </Container>
    </Fragment>
  );
};

export default Ecommerce;
