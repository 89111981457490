import moment from "moment-timezone";
import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";

import { DatePicker } from "antd";
import { MOMENT_SETTING } from "../../constant";
import ChartReusable from "../../chartReuseble/ChartReusable";
const { RangePicker } = DatePicker;

const PreviewPopup = (props) => {
  const { handlePreview, pageId, setStartData, setEndData } = props;
  const {
    priviewPopup,
    setPreviewPopup,
    priviewArr,
    categories,
    chartData,
    setCategories,
    setChartData,
    chartTitle,
  } = useContext(PersonalPageContext);

  const [checkValue, setCheckValue] = useState("chart");

  const dateChange = (value) => {
    handlePreview(
      pageId,
      value ? moment.utc(value[0].$d).format(MOMENT_SETTING.FORMATE) : null,
      value ? moment.utc(value[1].$d).format(MOMENT_SETTING.FORMATE) : null,
      null,
      chartTitle
    );
    setStartData(
      value ? moment.utc(value[0].$d).format(MOMENT_SETTING.FORMATE) : null
    );
    setEndData(
      value ? moment.utc(value[1].$d).format(MOMENT_SETTING.FORMATE) : null
    );
  };

  const handleGrid = (e) => {
    setCheckValue(e.target.value);
    setCategories([]);
    setChartData([]);
    handlePreview(pageId, null, null, e.target.value, chartTitle);
  };
  const handleList = (e) => {
    setCheckValue(e.target.value);
    setCategories([]);
    setChartData([]);
    handlePreview(pageId, null, null, e.target.value, chartTitle);
  };

  const closeModal = () => {
    setCategories([]);
    setChartData([]);
    setPreviewPopup(!priviewPopup);
    localStorage.removeItem("title");
  };

  return (
    <Modal size="xl" isOpen={priviewPopup} toggle={() => closeModal()}>
      <ModalHeader toggle={() => closeModal()}>
        <span>{"Show Preview"}</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6 mb-3">
            <RangePicker onChange={(value) => dateChange(value)} />
          </Col>
          <Col
            md="6 mb-3"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            <div className="radio radio-primary">
              <input
                id="radio7"
                type="radio"
                value={"chart"}
                onChange={(e) => handleGrid(e)}
                checked={checkValue === "chart"}
              />
              <Label htmlFor="radio7">{"Chart View"}</Label>
            </div>
            <div className="radio radio-primary">
              <input
                id="radio8"
                type="radio"
                value={"list"}
                onChange={(e) => handleList(e)}
                checked={checkValue === "list"}
              />
              <Label htmlFor="radio8">{"List View"}</Label>
            </div>
          </Col>
        </Row>
        <Row>
          {checkValue === "chart" ? (
            <ChartReusable
              categories={categories}
              arr={priviewArr}
              chartData={chartData}
            />
          ) : checkValue === "list" ? (
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col">{"Date"}</th>
                            <th scope="col">{"Views"}</th>
                            <th scope="col">{"Details"}</th>
                          </tr>
                        </thead>
                        {priviewArr.map((elem) => (
                          <tbody>
                            <tr>
                              <td>
                                {elem.date &&
                                  moment
                                    .utc(elem.date)
                                    .format(MOMENT_SETTING.FORMATE)}
                              </td>
                              <th scope="row">{elem.views}</th>
                              <td>
                                {elem.brokerDetails
                                  ? elem.brokerDetails.pageTitle
                                  : elem.pageDetails
                                  ? elem.pageDetails.pageTitle
                                  : "--"}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PreviewPopup;
