import React, { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import TopLoadingBar from "../../utils/topLoadingBar";
import { toast, Zoom } from "react-toastify";
import {
  Delete_OrgPageItem,
  OrgPageItem_Enable_Desable,
  ShortOrderPageItem,
  Show_OrgPageItem,
} from "../../api/Organization Api/OrganizationApi";
import { PAGE_ITEM_TYPE } from "../../constant";
import { randomStr } from "../../utils/helper";
import BrokerPageItemContent from "./BrokerPageItemContent";

import BrokerAddmodal from "../../components/BrokerPage/BrokerAddmodal";
import BrokerItemContext from "../../context/BrokertemContext/BrokertmContext";
import BrokerPageContext from "../../context/BrokerPageContext/BrokerPageContaxt";

const AddBrokerPageItem = () => {
  const {
    formvalue,
    setformValue,
    pageUuid,
    modal,
    setModal,
    setTitle,
    nfcGet,
  } = useContext(BrokerItemContext);

  const {
    setQpiId,
    seteditIndex,
    progress,
    setProgress,
    setChange,
    setLabel,
    setValue,
    setType,
    setLogo,
    setShareValue,
  } = useContext(BrokerPageContext);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [feilds, setFilds] = useState([
    {
      id1: randomStr(5),
      name: "",
      url: "",
      discription: "",
    },
  ]);

  /**
  useEffect to use of nfcGet() function call to and get function to PageItemContext and TopLoading call
   */

  useEffect(() => {
    nfcGet();
  }, []);

  useEffect(() => {
    setProgress(100);
  }, []);

  /**
   * click to add button ad open to popup to call openModel() functioon
   */

  const openModel = () => {
    setModal(true);
    setTitle(true);
  };

  /**
   * Edit Page Item
   */

  const EditHandle = async (qpiId) => {
    setQpiId(qpiId);
    seteditIndex(qpiId);
    try {
      const { data } = await Show_OrgPageItem(qpiId);
      if (data.status === 200) {
        seteditIndex(qpiId);
        setModal(true);
        setTitle(false);

        setChange(data.payload.data.itemId);
        setLabel(data.payload.data.label);
        setValue(data.payload.data.value);
        setType(data.payload.data.itemMaser.type);
        setLogo(data.payload.data.icon);
        setShareValue(data.payload.data.value);
        let value = data.payload.data.value;
        value = JSON.parse(value);
        if (value.length) {
          setFilds(
            value.map((elem) => {
              return {
                id1: elem.id1,
                name: elem.name,
                url: elem.url,
                discription: elem.discription,
              };
            })
          );
        }
      } else {
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Table Row Drag and Drop finctionality to call handleDragEnd() funcation call
   */

  const handleDragEnd = async (results) => {
    let tempUser = [...formvalue];
    let [selectdRow] = tempUser.splice(results.source.index, 1);
    tempUser.splice(results.destination.index, 0, selectdRow);
    setformValue(tempUser);
    try {
      let itemOrder = [];
      for (const item in tempUser) {
        itemOrder.push({ qpiId: tempUser[item].qpiId, sortOrder: +item + 1 });
      }
      const { data } = await ShortOrderPageItem(itemOrder);
      if (data.status === 200) {
        toast.success(data.response, {
          autoClose: 1000,
          transition: Zoom,
        });
      } else {
        // setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Enable desable page Item to use switch_onChange_handle() function call
   */

  const switch_onChange_handle = async (qpiId, isActive) => {
    let formData = {
      qpiId: qpiId,
      isActive: isActive ? false : true,
    };
    try {
      const { data } = await OrgPageItem_Enable_Desable(formData, pageUuid);
      if (data.status === 200) {
        await setIsSwitchOn(!isSwitchOn);
        await nfcGet();
        isActive
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Delete Page Item Handle
   */

  const DeleteHandle = async (qpiId) => {
    try {
      const { data } = await Delete_OrgPageItem(qpiId);
      if (data.status === 200) {
        nfcGet();
        toast.error(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      } else {
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Video and Gallary array string to json parse convert code
   */

  let video;
  let gallary;
  for (let t of formvalue) {
    if (t.itemMaser.type === PAGE_ITEM_TYPE.VIDEO) {
      video = JSON.parse(t.value);
    }
    if (t.itemMaser.type === PAGE_ITEM_TYPE.GALLAYRY) {
      gallary = JSON.parse(t.value);
    }
  }

  return (
    <>
      <Fragment>
        <Breadcrumbs parent="Page Item" />
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <BrokerPageItemContent
          openModel={openModel}
          handleDragEnd={handleDragEnd}
          video={video}
          gallary={gallary}
          switch_onChange_handle={switch_onChange_handle}
          EditHandle={EditHandle}
          DeleteHandle={DeleteHandle}
        />
        <BrokerAddmodal
          modal={modal}
          setModal={setModal}
          feilds={feilds}
          setFilds={setFilds}
        />
        {/* <BrokerPageItemViewPopup /> */}
      </Fragment>
    </>
  );
};

export default AddBrokerPageItem;
