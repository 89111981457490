import React, { useContext, useEffect, useState } from "react";
import OrganizationItem from "./PrivillgeManagerItem";
import OrganizationDetailsModel from "./PrivillageManagerDetailsModel";
import OrganizationAddModel from "./PrevillageManagerAddModel";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import {
  Add_PrivillageList,
  Delete_PrivillageList,
  Ditails_PrivillageList,
  Edit_PrivillageList,
  ModuleAccessList,
  ModuleList,
  PrivillageList,
} from "../../api/PrivillageApi/PrivillageApi";
import PrivillageMasterContext from "../../context/PrivillageMasterContext/PrivillageMasterContext";
import TopLoadingBar from "../../utils/topLoadingBar";
const Organization = () => {
  const {
    setformvalue,
    perModule,
    setPerModule,
    setModuleList,
    setModuleChaildList,
    setModuleAccessList,

    setahoeDataModel,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    editIndex,
    seteditIndex,
    uuid,
    setUuid,

    setName,
    name,

    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    val,

    setIsMenuOpen,
    searchSuppliar,
    setsearchSuppliar,
    setChialdPerModule,
    setSearchChild,
    moduleAccess,
    setModualAccess,

    method,
    setMethod,
    urls,
    setUrls,

    setShowModule,
    setPerModuleName,
    setChiModuleName,
    setChiModule,
    setmodualName,
  } = useContext(PrivillageMasterContext);

  const [pageSize, setPageSize] = useState(10);
  const [modulepageSize] = useState(10);
  const [ChaildmodulepageSize] = useState(10);
  const [modualAccessPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [id, setId] = useState("");

  const onMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const onMenuClose = () => setIsMenuOpen(false);
  //<<<<<<<<<<<<<<<--------------------TABLE ORG DATA API START-------------------------->>>>>>>>>>>>>>>
  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: val,
      };
      if (filter.length) {
        paramsObj["search"] = { name: filter };
      }
      const { data } = await PrivillageList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, sortOrder, sortBy, filter]);

  const getModuleData = async () => {
    try {
      let paramsObj = {
        recordsPerPage: modulepageSize,
        search: { parentId: null, name: searchSuppliar },
      };
      const { data } = await ModuleList(paramsObj);
      if (data.status === 200) {
        setModuleList(data.payload.data);
      } else {
        setModuleList([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getModuleData();
  }, [modulepageSize, searchSuppliar, perModule]);

  const handleChaildChange1 = async (chialdperModule) => {
    setChialdPerModule(chialdperModule.value);
    try {
      let paramsObj = {
        recordsPerPage: modualAccessPageSize,
        moduleId: chialdperModule.value,
      };
      const { data } = await ModuleAccessList(paramsObj);
      if (data.status === 200) {
        setModuleAccessList(data.payload.data);
      } else {
        setModuleAccessList([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const handleChaildInputChange1 = (chialdperModule) => {
    setSearchChild(chialdperModule);
  };

  const handleModualAccessChange2 = (moduleAccess) => {
    setModualAccess(moduleAccess.map((x) => x.value));
  };

  const onModualAccessChange = (moduleAccess, { action, prevInputValue }) => {
    if (action === "input-change") return moduleAccess;
    if (action === "menu-close") {
      if (prevInputValue) setIsMenuOpen(true);
      else setIsMenuOpen(undefined);
    }
    return prevInputValue;
  };

  const handleChange = async (perModule) => {
    setPerModule(perModule.value);
    try {
      let paramsObj = {
        recordsPerPage: ChaildmodulepageSize,
        search: { parentId: perModule.value },
      };
      const { data } = await ModuleList(paramsObj);
      if (data.status === 200) {
        setModuleChaildList(data.payload.data);
      } else {
        setModuleChaildList([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  const handleInputChange = (perModule) => {
    setsearchSuppliar(perModule);
  };

  useEffect(() => {
    setProgress(100);
  }, []);

  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };
  //<<<<<<<<<<<<<<<--------------------TABLE DATA API END-------------------------->>>>>>>>>>>>>>>

  //<<<<<<<<<<<<<<<<<<<<<<------------------EDIT ORG HANDLE START ---------------------->>>>>>>>>>>>>>>>>>>
  const editHandle = async (id) => {
    setUuid(id);
    try {
      const { data } = await Ditails_PrivillageList(id);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(id);
        setName(data.payload.data.name);
        setUrls(data.payload.data.url);
        setMethod(data.payload.data.method);

        if (data.payload.moduleList.length) {
          setPerModule(data.payload.moduleList.map((elem) => elem.parent.id));
          setPerModuleName(
            data.payload.moduleList.map((elem) => elem.parent.name)
          );
          setChiModule(data.payload.moduleList.map((elem) => elem.id));
          setChiModuleName(data.payload.moduleList.map((elem) => elem.name));

          setmodualName(
            data.payload.moduleList[0].moduleAccess.map((e) => ({
              label: e.accessName,
              value: +e.accessId,
            }))
          );
          setModualAccess(
            data.payload.moduleList[0].moduleAccess.map((e) => +e.accessId)
          );
          setId(data.payload.moduleList[0].moduleAccess.map((e) => e.id));
        }

        setModal(true);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  //<<<<<<<<<<<<<<<<<<<<<<------------------EDIT ORG HANDLE END ---------------------->>>>>>>>>>>>>>>>>>>

  const { register } = useForm();

  //<<<<<<<<<<<<<<<-----------------ADD DATA SUBMIT *OR* EDIT DATA SUBMIT START ------------------>>>>>>>>>>
  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex !== -1 && !togglesubmit) {
      formData = {
        name: name,
        method: method,
        url: urls,
        moduleAccessIds: moduleAccess,
        privilegeId: uuid,
      };

      // <<<<<<<<<<<<<<----------EDIT ORG API----------------->>>>>>>>>>>>
      try {
        const { data } = await Edit_PrivillageList(formData, uuid);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          setName("");
          setMethod("");
          setUrls("");
          setModualAccess([]);
        } else {
          setName(name);
          setMethod(method);
          setUrls(urls);
          setModualAccess([moduleAccess]);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      formData = {
        name: name,
        method: method,
        url: urls,
        moduleAccessIds: moduleAccess,
      };

      //<<<<<<<<------ADD ORG API --------------->>>>>>>>
      try {
        const { data } = await Add_PrivillageList(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          setName("");
          setMethod("");
          setUrls("");
          setModualAccess([]);
        } else {
          setName(name);
          setMethod(method);
          setUrls(urls);
          setModualAccess([moduleAccess]);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };
  //<<<<<<<<<<<<<<<-----------------ADD DATA SUBMIT *OR* EDIT DATA SUBMIT END ------------------>>>>>>>>>>
  //<<<<<<<<<<<<<<<<---------------------SHOW ORG DETAILS *OR* SHOW USER DETAILS START ----------->>>>>>>>>>>>>>>>
  const showHandle = async (id) => {
    setUuid(id);
    // <<<<-----ORG DETAILS SHOW API------>>>>>>
    try {
      const { data } = await Ditails_PrivillageList(id);
      if (data.status === 200) {
        setahoeDataModel(true);
        setName(data.payload.data.name);
        setUrls(data.payload.data.url);
        setMethod(data.payload.data.method);
        setShowModule(data.payload.moduleList);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const DeleteHandle = async (id) => {
    let formData = {
      privilegeId: id,
    };
    try {
      if (window.confirm("Are you sure you want to delete api?")) {
        const { data } = await Delete_PrivillageList(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          getData();
        } else {
        }
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  //<<<<<<<<<<<<<<<<---------------------SHOW ORG DETAILS *OR* SHOW USER DETAILS END ----------->>>>>>>>>>>>>>>>

  //<<<<<<<<<<<-------------SORTING START----------------->>>>>>>>>>>.
  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };
  //<<<<<<<<<<<-------------SORTING END----------------->>>>>>>>>>>.

  //<<<<<<<<<<<<<<----------------SEARCH FILTER START ------------->>>>>>>>>>>>>

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setFilter(e.target.value);
    } else {
      setFilter(e.target.value);
    }
  };

  //<<<<<<<<<<<<<<----------------SEARCH FILTER END------------->>>>>>>>>>>>>

  const close = () => {
    setModal(!modal);
    setName("");
    setUrls("");
    setMethod("");
    setPerModule("");
    setPerModuleName("");
    setChiModule("");
    setChiModuleName("");
    setmodualName("");
    setModualAccess("");
    setId("");
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel
          onSubmit={onSubmit}
          register={register}
          handleInputChange={handleInputChange}
          handleChange={handleChange}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          handleChaildChange1={handleChaildChange1}
          handleChaildInputChange1={handleChaildInputChange1}
          handleModualAccessChange2={handleModualAccessChange2}
          onModualAccessChange={onModualAccessChange}
          close={close}
        />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleFilter={handleFilter}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          DeleteHandle={DeleteHandle}
        />
      </div>
    </>
  );
};
export default Organization;
