import React, { useContext, useState } from "react";
import search from "../../../assets/images1/search.svg";
import downArrow from "../../../assets/images1/down-fill.svg";
import { useNavigate, useParams } from "react-router";
import HomePageContext from "../../../context/HomepageContext/HomePageContext";
import { BRANCH } from "../../../constant";

const Theme2branch = (props) => {
  const {
    branch,
    branches,
    orgPageDetails,
    setBranches,
    searchApiData,
    title,
    themeDetail,
  } = props;
  const { setOpen, open, setPageId } = useContext(HomePageContext);
  const [searchFeild, setSearchFeild] = useState("");

  const history = useNavigate();
  let { hwId } = useParams();

  const handleChange = (e) => {
    if (e.target.value === "") {
      setBranches(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item) =>
        item.pageTitle?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setBranches(filterResult);
    }
    setSearchFeild(e.target.value);
  };

  const clickBranch = async (pageId) => {
    setPageId(pageId);
    let uri = `/${hwId}${window.location.href.split(hwId)[1].split("?")[0]}?`;
    if (branch) {
      uri += `b=${branch}&`;
    }
    if (pageId) {
      uri += `p=${pageId}`;
    }
    await themeDetail(pageId, true);
    await orgPageDetails(pageId);
    history(uri);
    setOpen(false);
  };
  return (
    <div className="branch-wrapper">
      {branch === BRANCH.BRANCH ? (
        <p
          className="branch"
          onClick={() => setOpen(!open)}
          style={{ backgroundColor: "white" }}
        >
          {title}
          <img src={downArrow} className="img-fluid" alt="down" />
        </p>
      ) : (
        ""
      )}
      <div
        className={`branch-name ${
          open === true && "active"
        } d-inline-flex flex-wrap`}
      >
        <div className="branch-search position-sticky w-100">
          <input
            type="text"
            placeholder="Search"
            value={searchFeild}
            onChange={(e) => handleChange(e)}
          />
          <button>
            <img src={search} className="img-fluid" alt="search" />
          </button>
        </div>
        {branches !== undefined &&
          branches.map((elem) => {
            return (
              <p key={elem.pageId} onClick={() => clickBranch(elem.pageId)}>
                {elem.pageTitle}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default Theme2branch;
