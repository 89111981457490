import axios from "axios";
import {
  CREATE_INQUIRY_PAGE,
  CREATE_ORG_PAGE_ITEM,
  CREATE_ORG_PAGE_LIST,
  DELETE_ORG_PAGE_ITEM,
  DELETE_ORG_PAGE_LIST,
  DESHBOARD,
  DOWNLOAD_VFC,
  DROP_PAGE_ITEM_LIST,
  EDIT_ORG_PAGE_ITEM,
  EDIT_ORG_PAGE_LIST,
  INQUIRY_PAGE,
  ORG_PAGELIST_DETAILS,
  ORG_PAGE_DETAILS,
  ORG_PAGE_LIST,
  ORG_PAGE_LIST_ENABLE_DESABLE,
  PAGE_CLONE,
  PAGE_ITEM_LIST,
  PAGE_ITEM_PAGE_LIST_ENABLE_DESABLE,
  PAGE_PRIVIEW_LIST,
  SHOW_INQUIRY_PAGE,
  SHOW_ORG_PAGE_ITEM,
  SORT_ORDER_PAGE_ITEM,
  UPDATE_ORGANIZATION,
  UPLOADE,
} from "../../constant/API constant";

export const OrgPageList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_PAGE_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const PagePriviewList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PAGE_PRIVIEW_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

export const CreateOrgPageList = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_ORG_PAGE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
export const DeleteLandingPage = (pageUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_ORG_PAGE_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: { pageUuid: pageUuid },
    }
  );
};
export const PageClone = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${PAGE_CLONE}`, formData, {
    headers: { Authorization: jwt_token },
  });
};

export const Org_PageList_Details = (pageUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_PAGELIST_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { pageUuid },
  });
};

export const Edit_OrgPageList = (formData, pageUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ORG_PAGE_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { pageUuid },
    }
  );
};

export const OrgPageList_Enable_Desable = (formData, pageUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { pageUuid },
    }
  );
};

export const PageItemList = (pageUuid, search) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PAGE_ITEM_LIST}`, {
    headers: { Authorization: jwt_token },
    params: {
      pageUuid: pageUuid || localStorage.getItem("pageUuidForPageItem"),
      showAll: true,
      sortBy: "sortOrder",
      sortOrder: "ASC",
      search,
    },
  });
};

export const DropDownPageItemList = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DROP_PAGE_ITEM_LIST}`, {
    headers: { Authorization: jwt_token },
    params: { showAll: true },
  });
};

export const CreateOrgPageItem = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_ORG_PAGE_ITEM}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Show_OrgPageItem = (qpiId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SHOW_ORG_PAGE_ITEM}`, {
    headers: { Authorization: jwt_token },
    params: { qpiId: qpiId },
  });
};

export const Delete_OrgPageItem = (qpiId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${DELETE_ORG_PAGE_ITEM}`,
    {
      headers: { Authorization: jwt_token },
      params: { qpiId: qpiId },
    }
  );
};

export const Edit_OrgPageItem = (formData, qpiId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ORG_PAGE_ITEM}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { qpiId: qpiId },
    }
  );
};

export const OrgPageItem_Enable_Desable = (formData, qpiId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PAGE_ITEM_PAGE_LIST_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { qpiId },
    }
  );
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${UPLOADE}`, formData);
};

export const OrgPageDetails = (formData) => {
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_PAGE_DETAILS}`, {
    params: formData,
  });
};

export const Inquiry_Page = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${INQUIRY_PAGE}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const Show_Inquiry_Page = (inquiryId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SHOW_INQUIRY_PAGE}`, {
    headers: { Authorization: jwt_token },
    params: { inquiryId },
  });
};

export const CreateInquiry_Page = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_INQUIRY_PAGE}`,
    formData
  );
};

export const DownloadVfc = (pageUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DOWNLOAD_VFC}`, {
    headers: { Authorization: jwt_token, "Content-Type": "/vfc" },
    params: { pageUuid: pageUuid },
    responseType: "blob",
  });
};

export const ShortOrderPageItem = (qpiIdArray) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${SORT_ORDER_PAGE_ITEM}`,
    { qpiIdArray: qpiIdArray },
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const DeshBoard = () => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DESHBOARD}`, {
    headers: { Authorization: jwt_token },
  });
};
export const UpdateOrganization = (formData, orgUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_ORGANIZATION}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orgUuid },
    }
  );
};
