import React, { useState } from "react";
import ReactPlayer from "react-player";
import {
  Carousel,
  // CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { PAGE_ITEM_TYPE } from "../../../constant";

const Theme2VideoGallary = (props) => {
  const { model, setModel, formValue } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  /**
   * next button
   */
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === video.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /**
   * Previus button
   */

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? video.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /**
   * Goto Index function
   */

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  };

  /**
   * Video array stirng to json parse code
   */

  let video;
  for (let k of formValue) {
    if (k.itemMaser.type === PAGE_ITEM_TYPE.VIDEO) {
      video = JSON.parse(k.value);
    } else {
    }
  }

  return (
    <Modal
      size="lm"
      isOpen={model}
      toggle={() => setModel(false)}
      className="inquiry-modal"
    >
      <ModalHeader
        className="justify-content-center border-0"
        toggle={() => setModel(false)}
      >
        <span>{PAGE_ITEM_TYPE.GALLARY_POPUP}</span>
      </ModalHeader>
      <ModalBody>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={video}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {video !== undefined
            ? video.map((elem) => {
                return (
                  <CarouselItem
                    key={elem.id1}
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                  >
                    <ReactPlayer
                      url={elem.url}
                      width="100%"
                      pip={true}
                      controls={true}
                      playing={false}
                    />
                    <div>
                      <h3>{elem.name}</h3>
                      <p
                        style={{
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        {elem.discription}
                      </p>
                    </div>
                    {/* <CarouselCaption></CarouselCaption> */}
                  </CarouselItem>
                );
              })
            : null}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </ModalBody>
    </Modal>
  );
};

export default Theme2VideoGallary;
