import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

// import "../../assets/bootstrap.min.css";
import "../../assets/main.css";
import "../../assets/slick.css";

import { CreateInquiry_Page } from "../../api/Organization Api/OrganizationApi";

import { SuccessToast, Toast } from "../../auth/signinToast";
import HomePageContext from "../../context/HomepageContext/HomePageContext";
import AboutPage from "./AboutPage";
import GalaryPopup from "./GalaryPopup";
import InquaryPage from "./InquaryPage";
import Followme from "./Followme";
import IconsAndLogo from "./IconsAndLogo";
import Branchis from "./Branchis";
import useQuery from "../../route/AuthRoutes";
import QrPopup from "./QrPopup";
import SharePopup from "./SharePopup";
import Theme2 from "./Theme2/theme2";

const HomePage = (props) => {
  let query = useQuery();

  let { hwId } = useParams();
  localStorage.setItem("hwId", hwId);
  const {
    about,
    contact,
    setContact,
    formValue,
    socialItem,
    name,
    setName,
    email,
    setEmail,
    mobile,
    setMobile,
    subject,
    setSubject,
    message,
    setMessage,
    pageUuid,
    setPageUuid,

    title,
    setAbout,
    orgId,
  } = useContext(HomePageContext);

  const [abouticon, setAboutIcon] = useState("");
  const [aboutlabel, setAboutLabel] = useState("");
  const [aboutvalue, setAboutValue] = useState("");
  const [abouttype, setAboutType] = useState("");

  const {
    nfcGet,
    orgPageDetails,
    branches,
    searchApiData,
    color,
    icon,
    setBranches,
    brokerId,
    domain,
    clicktoCount,
    templateId,
    themeDetail,
    getPageId,
  } = props;

  useEffect(() => {
    nfcGet();
  }, []);

  useEffect(() => {
    orgPageDetails();
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = icon;
  }, [icon]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='apple-touch-icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "apple-touch-icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = icon;
  }, [icon]);

  /**
   * Emplty Efild Function
   */

  const EmpltyFeild = () => {
    setName("");
    setMobile("");
    setPageUuid("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  /**
   * Create inquary to submit form
   */

  const onSubmit = async (e) => {
    debugger;
    e.preventDefault();
    let formData = {
      name,
      mobile,
      pageId: query?.get("p") ? query?.get("p") : getPageId,
      email,
      subject,
      message,
      pageUuid,
      orgId,
    };
    if (brokerId) {
      formData["brokerId"] = brokerId;
      formData["orgId"] = orgId;
    }

    try {
      const { data } = await CreateInquiry_Page(formData);
      if (data.status === 200) {
        SuccessToast.fire({
          icon: "success",
          title: data.message,
        });
        EmpltyFeild();
        setContact(false);
      } else {
        setName(name);
        setMobile(mobile);
        setPageUuid(pageUuid);
        setEmail(email);
        setSubject(subject);
        setMessage(message);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.response.data.message,
      });
    }
  };

  /**
   * click to about icon to get details
   */

  const abooutClick = (icon, label, value, type, qpiId, pageId) => {
    setAboutIcon(icon);
    setAboutLabel(label);
    setAboutValue(value);
    setAboutType(type);
    setAbout(!about);
    clicktoCount(qpiId, pageId);
  };

  return (
    <>
      {+templateId === 2 && (
        <>
          <Theme2
            branch={query?.get("b")}
            quary={query}
            branches={branches}
            setBranches={setBranches}
            orgPageDetails={orgPageDetails}
            searchApiData={searchApiData}
            color={color}
            title={title}
            abooutClick={abooutClick}
            clicktoCount={clicktoCount}
            brokerId={brokerId}
            /**about */
            formValue={formValue}
            abouticon={abouticon}
            aboutlabel={aboutlabel}
            aboutvalue={aboutvalue}
            abouttype={abouttype}
            domain={domain}
            onSubmit={onSubmit}
            themeDetail={themeDetail}
          />
          <p
            className="copyrite"
            style={{ textAlign: "center", fontSize: "11px" }}
          >
            <a
              href="https://bytenfc.com/A5DKA7R2"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Developed by <span style={{ color: "#7366ff" }}>AJ Infosoft</span>
            </a>
          </p>
        </>
      )}
      {+templateId === 1 && (
        <>
          {" "}
          <div className="card-wrapper">
            <Branchis
              branch={query?.get("b")}
              branches={branches}
              setBranches={setBranches}
              orgPageDetails={orgPageDetails}
              searchApiData={searchApiData}
              color={color}
              title={title}
              themeDetail={themeDetail}
            />
            <IconsAndLogo
              color={color}
              abooutClick={abooutClick}
              clicktoCount={clicktoCount}
            />
            {socialItem.length || brokerId ? (
              <Followme
                brokerId={brokerId}
                orgPageDetails={orgPageDetails}
                clicktoCount={clicktoCount}
                themeDetail={themeDetail}
              />
            ) : (
              ""
            )}
            <p
              className="copyrite"
              style={{ textAlign: "center", fontSize: "11px" }}
            >
              <a
                href="https://bytenfc.com/A5DKA7R2"
                target="_blank"
                rel="noreferrer"
                style={{ color: "black" }}
              >
                Developed by{" "}
                <span style={{ color: "#7366ff" }}>AJ Infosoft</span>
              </a>
            </p>
          </div>
          <div className="overlay"></div>
          {
            <AboutPage
              formValue={formValue}
              color={color}
              abouticon={abouticon}
              aboutlabel={aboutlabel}
              aboutvalue={aboutvalue}
              abouttype={abouttype}
            />
          }
          <SharePopup domain={domain} />
          {<GalaryPopup formValue={formValue} />}
          {contact && <InquaryPage onSubmit={onSubmit} />}
          <QrPopup />{" "}
        </>
      )}
      {templateId === "" && (
        <>
          {" "}
          <div className="card-wrapper">
            <Branchis
              branch={query?.get("b")}
              branches={branches}
              setBranches={setBranches}
              orgPageDetails={orgPageDetails}
              searchApiData={searchApiData}
              color={color}
              title={title}
              themeDetail={themeDetail}
            />
            <IconsAndLogo
              color={color}
              abooutClick={abooutClick}
              clicktoCount={clicktoCount}
            />
            {socialItem.length || brokerId !== query?.get("p") ? (
              <Followme
                brokerId={brokerId}
                quary={query.get("p")}
                orgPageDetails={orgPageDetails}
                clicktoCount={clicktoCount}
                themeDetail={themeDetail}
              />
            ) : (
              ""
            )}
          </div>
          <div className="overlay"></div>
          {
            <AboutPage
              formValue={formValue}
              color={color}
              abouticon={abouticon}
              aboutlabel={aboutlabel}
              aboutvalue={aboutvalue}
              abouttype={abouttype}
            />
          }
          <SharePopup domain={domain} />
          {<GalaryPopup formValue={formValue} />}
          {contact && <InquaryPage onSubmit={onSubmit} />}
          <QrPopup />{" "}
        </>
      )}
    </>
  );
};

export default HomePage;
