import React, { useContext } from "react";
import HomePageContext from "../../../context/HomepageContext/HomePageContext";
import Theme2AboutPage from "./theme2-AboutPage";
import Theme2branch from "./theme2-branch";
import Theme2Follow from "./theme2-follow";
import Theme2GallaryPopup from "./theme2-GallaryPopup";
import Theme2IconAndLogo from "./theme2-IconAndLogo";
import Theme2IquaryPage from "./theme2-IquaryPage";
import Theme2QrPopup from "./theme2-QrPopup";
import Theme2SharePopup from "./theme2-SharePopup";

const Theme2 = (props) => {
  const {
    branch,
    branches,
    orgPageDetails,
    setBranches,
    searchApiData,
    color,
    title,

    abooutClick,
    clicktoCount,
    brokerId,

    formValue,
    abouticon,
    aboutlabel,
    aboutvalue,
    abouttype,

    domain,

    onSubmit,
    themeDetail,
    query,
  } = props;

  const { socialItem, contact } = useContext(HomePageContext);

  return (
    <div className="second-card">
      <div className="card-wrapper">
        <Theme2branch
          branch={branch}
          branches={branches}
          orgPageDetails={orgPageDetails}
          setBranches={setBranches}
          searchApiData={searchApiData}
          color={color}
          title={title}
          themeDetail={themeDetail}
        />
        <Theme2IconAndLogo
          color={color}
          abooutClick={abooutClick}
          clicktoCount={clicktoCount}
        />
        {socialItem.length || brokerId !== query?.get("p") ? (
          <Theme2Follow
            brokerId={brokerId}
            quary={query?.get("p")}
            orgPageDetails={orgPageDetails}
            clicktoCount={clicktoCount}
            themeDetail={themeDetail}
          />
        ) : (
          ""
        )}
      </div>

      <div className="overlay"></div>

      <Theme2AboutPage
        formValue={formValue}
        abouticon={abouticon}
        aboutlabel={aboutlabel}
        aboutvalue={aboutvalue}
        abouttype={abouttype}
      />

      <Theme2SharePopup domain={domain} />

      <Theme2GallaryPopup formValue={formValue} />

      {contact && <Theme2IquaryPage onSubmit={onSubmit} />}
      <Theme2QrPopup />

      <div className="modal fade" id="inquiry-form">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <h2 className="modal-title text-center">inquiry form</h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <form>
                <div className="form-item">
                  <label for="name" className="form-label">
                    name
                  </label>
                  <input type="text" className="form-control" id="name" />
                </div>
                <div className="form-item">
                  <label for="mobile" className="form-label">
                    mobile number
                  </label>
                  <input type="tel" className="form-control" id="mobile" />
                </div>
                <div className="form-item">
                  <label for="email" className="form-label">
                    Email address
                  </label>
                  <input type="email" className="form-control" id="email" />
                </div>
                <div className="form-item">
                  <label for="subject" className="form-label">
                    subject
                  </label>
                  <input type="text" className="form-control" id="subject" />
                </div>
                <div className="form-item">
                  <label for="message" className="form-label">
                    message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                  ></textarea>
                </div>
                <button type="submit" className="theme-btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme2;
