import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HashLoader from "react-spinners/HashLoader";
import { Card, CardBody, Col, Row } from "reactstrap";
import LoaderContext from "../context/LoaderContext/LoaderContext";

export const LoaderResponse = () => {
  const { loaderShow } = useContext(LoaderContext);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    zIndex: "999999999",
    width: "100%",
    /* background-color: rgb(177 177 177 / 65%); */
    backgroundColor: "#b293bf75",
  };
  return (
    loaderShow === true && (
      <div className="loader" style={style}>
        {" "}
        <HashLoader
          color={"#883ea6"}
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  );
};

export const TabelAnimation = () => {
  const { tabelAnimation } = useContext(LoaderContext);
  const NumberOfTabelRow = 7;

  const newArray = [];
  for (let i = 0; i < NumberOfTabelRow; i++) {
    newArray.push(
      <div
        style={{
          width: "250px",
          paddingLeft: "15px",
          paddingTop: "13px",
          paddingRight: "13px",
        }}
      >
        <Skeleton count={10} height={15} style={{ marginBottom: "10px" }} />
      </div>
    );
  }
  return (
    <>
      {tabelAnimation === true && (
        <div style={{ display: "flex" }}>{newArray}</div>
      )}
    </>
  );
};

export const DeshboardAnimation = () => {
  // const { deshboardAnimation } = useContext(LoaderContext);
  const NumberOfTabelRow = 7;

  const newArray = [];
  for (let i = 0; i < NumberOfTabelRow; i++) {
    newArray.push(
      <Row className="size-column">
        <Row>
          <Col xl="4" lg="12" md="6" className="box-col-6" key={i}>
            <Card className="o-hidden">
              {/* <CardBody>
                <div className="ecommerce-widgets media">
                </div>
              </CardBody> */}
              <Skeleton width={545} />
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </Row>
    );
  }
  return <>{<div>{newArray}</div>}</>;
};
