import axios from "axios";
import {
  CREATE_HWID,
  ORGANIZATION_LIST,
  UPDATE_PAGE_LIMIT,
} from "../../constant/API constant";

export const Organization_List = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORGANIZATION_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
export const CreateHwId = (formdata) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_HWID}`,
    formdata,
    {
      headers: { authorization: jwt_token },
    }
  );
};

export const EditPageLimit = (formdata, orgUuid) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${UPDATE_PAGE_LIMIT}`,
    formdata,
    {
      headers: { authorization: jwt_token },
      params: { orgUuid },
    }
  );
};
